import moment from 'moment';

const _state = {
  data: null,
  events: null,
  limits: null,
  switching: false,
};

const getters = {
  data: (state) => state.data,
  domain: (state) => state.data?.domain,
  unsubscribeSettings: (state) => ({
    color: state.data?.unsubscribe?.color || null,
    image: state.data?.unsubscribe?.image || null,
    languages: state.data?.unsubscribe?.languages || [],
  }),
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_DATA(state, domain) {
    state.data = domain;
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_LIMITS(state, limits) {
    state.limits = limits;
  },
  SET_SWITCHING(state, switching) {
    state.switching = switching;
  },
  SET_PLAN(state, isPremium) {
    if (state.data && state.data.premium) {
      state.data.premium.plan = isPremium ? 'business' : 'free';
    }
  },
};

/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    const domain = await this.$api.domain.fetch();

    commit('SET_DATA', domain);

    return domain;
  },
  async update({ dispatch }, data) {
    const domain = await this.$api.domain.update(data);

    await dispatch('fetch');

    return domain;
  },

  async upgrade({ commit, dispatch }, isPremium) {
    const domain = await this.$api.domain.upgrade(isPremium);
    commit('SET_PLAN', isPremium);
    await dispatch('fetch');

    return domain;
  },

  async admin({ dispatch }, payload) {
    await this.$api.domain.admin(payload);
    await dispatch('fetch');
  },

  async testWebhook(_ctx, webhookUrl) {
    return this.$api.domain.testWebhook({ webhookUrl });
  },

  async blockDomainTracking({ dispatch }, payload) {
    await this.$api.domain.blockDomainTracking(payload);
    await dispatch('fetch');
  },

  async updateSubSettings({ dispatch }, payload) {
    const domain = await this.$api.domain.updateSubSettings(payload);
    await dispatch('fetch');
    return domain;
  },

  async getSubSettings({ commit }, domain) {
    const settings = await this.$api.domain.getSubSettings(domain);
    commit('SET_UNSUB', settings);
    return settings;
  },

  async remove({ dispatch }) {
    const domain = await this.$api.domain.remove();

    await dispatch('fetch');

    return domain;
  },
  async create({ dispatch }, payload) {
    const domain = await this.$api.domain.create(payload);

    await dispatch('fetch');

    return domain;
  },
  async switch({ commit, dispatch }, id) {
    commit('SET_SWITCHING', true);
    const domain = await this.$api.domain.activate({ domain: id });

    await dispatch('fetch');
    await dispatch('initSwitch');

    commit('SET_SWITCHING', false);

    return domain;
  },
  async getEvents({ commit }, page) {
    const events = await this.$api.domain.getEvents(page);

    await commit('SET_EVENTS', events);

    return events;
  },
  async getLimits({ commit }) {
    const limits = await this.$api.domain.getLimits();

    await commit('SET_LIMITS', limits);

    return limits;
  },
  async initSwitch({ dispatch }) {
    dispatch('analytics/setFilters', [], { root: true });

    const fetchEssentials = [
      dispatch('company/fetch', {}, { root: true }),
      dispatch('website/cases/fetch', {}, { root: true }),
      dispatch('website/journeys/fetch', {}, { root: true }),
    ];
    await Promise.all(fetchEssentials);

    Promise.all([
      dispatch('website/conversions/fetch', {}, { root: true }),
      dispatch('website/media/fetchCollections', {}, { root: true }),
      dispatch('email/templates/fetch', {}, { root: true }),
      dispatch('website/audience/fetch', {}, { root: true }),
      dispatch('website/business/setData', {}, { root: true }),
      dispatch('website/capturedata/fetch', {}, { root: true }),
      dispatch('website/datasource/fetch', {}, { root: true }),
      dispatch('website/integrations/fetch', {}, { root: true }),
      dispatch('website/rfm/fetch', {}, { root: true }),
      dispatch('billing/fetchPricingTiers', {}, { root: true }),
      dispatch('billing/fetchEstimatedPageviews', {}, { root: true }),
      dispatch('company/fetch', {}, { root: true }),
      dispatch('interests/fetch', {}, { root: true }),
      dispatch('website/media/fetchFiles', {}, { root: true }),
    ]);

    await dispatch(
      'analytics/setPeriod',
      {
        start: moment().subtract(7, 'days').toDate(),
        end: moment().subtract(1, 'days').toDate(),
      },
      { root: true },
    );
    await dispatch('analytics/fetch', {}, { root: true });
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
