import axios from '@services/api/axios';

export const getUserOnboarding = async () => {
  // eslint-disable-next-line no-useless-catch

  const response = await axios.get('/user/onboarding');
  return response.data;
};

export const putUserOnboarding = async (payload) => {
  //     {
  //   "flow": "journey",
  //   "status": "started",
  //   "step": 1
  // }
  // flow must be audience, journey, email_campaign, performance or integrations
  // eslint-disable-next-line no-useless-catch

  const response = await axios.put('/user/onboarding', payload);

  return response.data;
};
