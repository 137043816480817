const _state = {
  list: [],
  amounts: [],
  loading: false,
  loadingAmounts: false,
  filters: [],
};

const getters = {
  audiences: (state) => state.list,
  isLoading: (state) => state.loading,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LIST(state, audiences) {
    state.list = audiences.map((item) => ({
      ...item,
      members: state.amounts.find((aud) => aud.audienceId === item._id)?.members ?? 0,
    }));
  },
  SET_LIST_AMOUNTS(state, amounts) {
    state.amounts = amounts;
    state.list = state.list.map((item) => ({
      ...item,
      members: amounts.find((aud) => aud.audienceId === item._id)?.members ?? 0,
    }));
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOADING_AMOUNTS(state, loading) {
    state.loadingAmounts = loading;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
};

/* eslint-enable no-param-reassign */
const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);
    const audiences = await this.$api.website.audience.fetchAudiences();

    commit('SET_LIST', audiences);
    commit('SET_LOADING', false);

    return audiences;
  },
  async fetchWithoutLoader({ commit }) {
    const audiences = await this.$api.website.audience.fetchAudiences();

    commit('SET_LIST', audiences);

    return audiences;
  },
  async fetchAudiencesAmounts({ commit }) {
    commit('SET_LOADING_AMOUNTS', true);
    const audiencesAmounts = await this.$api.website.audience.fetchAudiencesAmounts();

    commit('SET_LIST_AMOUNTS', audiencesAmounts);
    commit('SET_LOADING_AMOUNTS', false);

    return audiencesAmounts;
  },
  async fetchVisitors(_, { audienceId, page }) {
    const visitors = await this.$api.website.audience.fetchVisitors(audienceId, page);

    return visitors;
  },
  async fetchFilters({ commit }) {
    const filters = await this.$api.website.audience.fetchFilters();

    commit('SET_FILTERS', filters);

    return filters;
  },
  async create({ dispatch }, data) {
    const audience = await this.$api.website.audience.createAudience(data);

    await dispatch('fetch');

    return audience;
  },
  async update({ dispatch }, { id, data }) {
    const audience = await this.$api.website.audience.updateAudience(id, data);

    await dispatch('fetch');

    return audience;
  },
  async remove({ dispatch }, id) {
    const audience = await this.$api.website.audience.removeAudience(id);

    await dispatch('fetch');

    return audience;
  },
  async sendAudienceMail(_, { id, payload }) {
    await this.$api.website.audience.sendAudienceMail(id, payload);
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
