import axios from '@services/api/axios';

export const fetchFiles = async () => {
  const response = await axios.get('/media/files');

  return response.data;
};
export const deleteFile = async (id) => {
  const response = await axios.delete(`/media/files/${id}`);

  return response.data;
};
export const fetchCollections = async () => {
  const response = await axios.get('/media/collections');

  return response.data;
};
export const createCollection = async (payload) => {
  const response = await axios.post('/media/collections', payload);

  return response.data;
};
export const removeFromCollection = async (fileId, collectionId) => {
  const response = await axios.put(`/media/collections/remove/${collectionId}`, {
    file: fileId,
  });
  return response.data;
};
export const addFileToCollection = async (fileId, collectionId) => {
  const response = await axios.put(`/media/collections/add/${collectionId}`, {
    file: fileId,
  });
  return response.data;
};
export const deleteCollection = async (id) => {
  const response = await axios.delete(`/media/collections/${id}`);

  return response.data;
};
export const uploadImages = async (payload, collectionId) => {
  const fd = new FormData();
  fd.append('file', payload, payload.name);

  const { data } = await axios.post('/upload', fd);

  const response = await axios.post('/media/files', {
    location: data.path,
    name: data.name,
    size: data.size,
    mimetype: data.mimetype,
    filename: data.name,
    collection: collectionId,
  });
  return response.data;
};
