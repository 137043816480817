export default [
  {
    path: 'website',
    children: [
      {
        path: '',
        redirect: '/performance/website/conversions',
      },
      {
        path: 'ab-tests',
        name: 'stage-performance',
        component: () => import('./ab-tests/ab-tests.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'conditions',
        name: 'condition-performance',
        component: () => import('./conditions/conditions-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'interests',
        name: 'interest-performance',
        component: () => import('./interests/interests-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'conversions',
        name: 'conversion-performance',
        component: () => import('./conversions/conversions-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'conversion-predictions',
        name: 'conversion-prediction-performance',
        component: () => import('./conversion-predictions/conversion-predictions.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'rfm',
        name: 'rfm-performance',
        component: () => import('./rfm/rfm-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      /*
      {
        path: 'recommendations',
        component: () => import('./recommendations/recommendations-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      */
    ],
  },
];
