import axios from '@services/api/axios';

export const fetchAudiences = async () => {
  const response = await axios.get('/website/audience/domain');

  return response.data;
};

export const fetchAudiencesAmounts = async () => {
  const response = await axios.get('website/audience/domain/amount');

  return response.data;
};

export const fetchVisitors = async (audienceId, page = 1) => {
  const response = await axios.post(`/website/audience/data/${audienceId}?page=${page}`);

  return response.data;
};

export const fetchFilters = async () => {
  const response = await axios.get('/website/audience/filters');
  return response.data;
};

export const createAudience = async (payload) => {
  const response = await axios.post('/website/audience/create', payload);

  return response.data;
};

export const updateAudience = async (id, payload) => {
  const response = await axios.put(`/website/audience/update/${id}`, payload);

  return response.data;
};

export const removeAudience = async (id) => {
  const response = await axios.delete(`/website/audience/remove/${id}`);

  return response;
};

export const sendAudienceMail = async (audienceId, payload) => {
  const response = await axios.post(`/website/audience/${audienceId}/send/email/`, payload);

  return response;
};
