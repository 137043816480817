export default [
  {
    path: '/product',
    children: [
      {
        path: '',
        redirect: '/product/collections',
      },
      {
        path: 'items',
        name: 'scraper-products',
        component: () => import('./pages/products-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'collections',
        name: 'scraper-collections',
        component: () => import('./pages/collections-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'feeds',
        name: 'scraper-feeds',
        component: () => import('./pages/feeds-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
