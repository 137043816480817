export default [
  {
    path: 'channels',
    children: [
      {
        path: '',
        redirect: '/performance/channels/cases',
      },
      {
        path: 'cases',
        name: 'case-performance',
        component: () => import('./cases/cases-performance.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'email',
        name: 'email-performance',
        component: () => import('./email/emails-performance.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'notifications',
        name: 'notification-performance',
        component: () => import('./notifications/notifications-performance.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'whatsapp',
        component: () => import('./whatsapp/whatsapp-performance.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
