export default [
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('./integrations-page.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
