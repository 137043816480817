import regexes from './regexes';

const domain = (value, params, { field }) => {
  if (!value) return `The '${field}' field is required`;
  if (!regexes.domainRegex.test(value)) return `The '${field}' field is not a valid domain`;

  return true;
};

const region = (value, params, { field }) => {
  if (typeof value !== 'object' || value === null) {
    return `${field} must be an object`;
  }

  const { country, region: regionObj } = value;

  if (!country || country.label.trim() === '' || country.value.trim() === '') {
    return 'Country cannot be empty';
  }

  if (!regionObj || regionObj.label.trim() === '' || regionObj.value.trim() === '') {
    return 'Region cannot be empty';
  }

  return true;
};

export default {
  domain,
  region,
};
