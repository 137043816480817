import moment from 'moment';
import jStat from 'jstat';
import { getAnalyticValue, calculateConversions } from '../main';

/**
 * Calculates the amount of visitors needed per variation to give estimate
 * of how long A/B test should run.
 *
 * @param {Number} p Baseline conversion percentage (of default variation), conversions / visitors
 * @returns {Number} Amount of visitors needed per variation.
 */
export const calcSampleSize = (p) => {
  const alpha = 0.05;
  const power = 0.8;
  const dRel = 0.2;
  const d = p * dRel;
  const Zalpha2 = jStat.normal.inv(1 - alpha / 2, 0, 1);
  const Zbeta = jStat.normal.inv(power, 0, 1);
  return 2 * (Zalpha2 + Zbeta) ** 2 * ((p * (1 - p)) / d ** 2);
};

const calcStandardError = (convPercentage, sampleSize) => ((convPercentage * (1 - convPercentage)) / sampleSize) ** 0.5;

const zTest = (convPercentageDefault, convPercentageVariation, visitorsDefault, visitorsVariation) => {
  const standardErrorDefault = calcStandardError(convPercentageDefault, visitorsDefault);
  const standardErrorVariation = calcStandardError(convPercentageVariation, visitorsVariation);
  const numerator = convPercentageVariation - convPercentageDefault;
  const denominator = (standardErrorDefault ** 2 + standardErrorVariation ** 2) ** 0.5;

  return numerator / denominator;
};

/**
 * Calculate statistic significance percentage.
 * (Input & output percentage values as a number between 0 & 1)!
 *
 * @param {Number} convPercentageDefault Conversion percentage of default scenario
 * @param {Number} convPercentageVariation Conversion percentage of variation scenario
 * @param {Number} visitorsDefault Total visitors of default scenario
 * @param {Number} visitorsVariation Total visitors of variation scenario
 * @return {Number} Statistic significance percentage
 */
export const statisticPercentage = (convPercentageDefault, convPercentageVariation, visitorsDefault, visitorsVariation) => {
  const zScore = zTest(convPercentageDefault, convPercentageVariation, visitorsDefault, visitorsVariation);
  const pValue = jStat.ztest(zScore, 1);
  return zScore > 0 ? pValue : 100 - pValue;
};

const getSubstageName = (substageId, substages) => {
  const found = substages.find((substage) => substage.stageId === substageId || substage.stageId.replace(/__/g, '_') === substageId);
  if (found) return found.value;

  return 'unknown';
};

const getVariationIndex = (variationId, substages) => {
  const index = variationId.split('_')[variationId.split('_').length - 1];
  if (Number.isInteger(parseInt(index, 10))) return parseInt(index, 10);
  if (index === 'true') return 0;
  if (index === 'false') return 1;

  // RFM segments have '_segmentId' or '_other'
  const options = substages.map((subs) => subs.stageId.replace(/__/g, '_')).sort();
  return options.indexOf(variationId);
};

const getStageConversions = ({ analytics, stage, filters, childId }) =>
  calculateConversions({
    analytics,
    channel: stage,
    channelIdName: 'stageId',
    childrenName: 'substages',
    filters,
    conversionChild: 'stages',
    childId,
  });

/* eslint-disable no-param-reassign */
const _mapper = (conversionData, visitorData, substage) =>
  conversionData
    .map((entry) => ({
      conversions: entry.data.reduce((acc, conv) => {
        conv.stages.forEach((stage) => {
          stage.substages.forEach((substageData) => {
            if (substageData.stageId === substage.stageId) acc += substageData.count;
          });
        });
        return acc;
      }, 0),
      visitors: visitorData
        .find((vEntry) => vEntry.date === entry.date)
        .data.reduce((acc, vis) => {
          vis.substages.forEach((substageData) => {
            if (substageData.stageId === substage.stageId) acc += substageData.visitors;
          });
          return acc;
        }, 0),
    }))
    .map(({ conversions, visitors }) => (visitors && visitors > 0 ? conversions / visitors : 0));
/* eslint-enable no-param-reassign */

const createChart = (conversionData, visitorData, substages) => ({
  xasCategories: conversionData.map((day) => moment(day.date).format('D MMM YYYY')),
  series: substages.map((substage) => ({
    name: substage.name,
    stageId: substage.stageId,
    data: _mapper(conversionData, visitorData, substage),
  })),
});

const formatConversionsForChart = (data, filters) =>
  data.reduce((prev, upcoming) => {
    prev.push({
      date: upcoming.date,
      data: upcoming.data.filter((conv) => (filters.length < 1 ? true : filters.indexOf(conv.conversionId) > -1)),
    });
    return prev;
  }, []);

export const isBestVariation = (rate, variations) => {
  const conversionRates = variations.map((obj) => obj.conversionRate);
  const hightestConversionRate = Math.max(...conversionRates);
  const best = rate ? rate >= hightestConversionRate && conversionRates.filter((obj) => obj === rate).length === 1 : false;

  return best;
};

export const bestVariationIncrease = (winingRate, variations) => {
  const losingVariations = variations.filter((obj) => obj.conversionRate < winingRate).map((obj) => obj.conversionRate);
  const highestLosinRate = Math.max(...losingVariations);

  return (winingRate - highestLosinRate) / highestLosinRate;
};

const COLORS = [
  '#0C88F7',
  '#FFA524',
  '#00BD8C',
  '#B676FF',
  '#FF3044',
  '#0C88F7',
  '#FFA524',
  '#00BD8C',
  '#B676FF',
  '#FF3044',
  '#0C88F7',
  '#FFA524',
  '#00BD8C',
  '#B676FF',
  '#FF3044',
  '#0C88F7',
  '#FFA524',
  '#00BD8C',
  '#B676FF',
  '#FF3044',
];

const getBreakdownConversions = ({ analytics, stage, substageId, stageBreakdownFilter, filters }) => {
  let conversionsPerStage = analytics[`conversions_per_stage_${stageBreakdownFilter}`];
  //
  if (!conversionsPerStage) return [];
  if (filters.length) conversionsPerStage = conversionsPerStage.filter((conversion) => filters.includes(conversion.conversionId));

  return conversionsPerStage.reduce((acc, conversion) => {
    const stageConversions = conversion.stages.find((entry) => entry.stageId === stage.stageId);
    if (!stageConversions) return [];

    const substage = stageConversions.substages.find((sstg) => sstg.stageId === substageId);
    if (substage) {
      substage[stageBreakdownFilter].forEach((breakdown) => {
        const entryExistingInAcc = acc.find((entry) => entry[stageBreakdownFilter] === breakdown[stageBreakdownFilter]);
        if (entryExistingInAcc) {
          entryExistingInAcc.count += breakdown.count;
          entryExistingInAcc.value += breakdown?.value ?? 0;
        } else {
          acc.push({
            ...breakdown,
            value: breakdown?.value ?? 0,
          });
        }
      });
    }

    return acc;
  }, []);
};

const getBreakdownAnalyticValue = ({ analytics, analyticValue, stage, substageId, stageBreakdownFilter }) => {
  const analyticsValue = analytics[`${analyticValue}_per_stage_${stageBreakdownFilter}`];
  const stageAnalytics = analyticsValue.find((entry) => entry.stageId === stage.stageId);
  if (!stageAnalytics) return [];
  const substage = stageAnalytics.substages.find((sstg) => sstg.stageId === substageId);
  if (substage) return substage[stageBreakdownFilter];
  return [];
};

const getStageBreakdowns = ({ analytics, stage, substageId, stageBreakdownFilter, filters }) => {
  if (!stageBreakdownFilter) return [];
  const conversionsPerBreakdown = getBreakdownConversions({
    analytics,
    stage,
    substageId,
    stageBreakdownFilter,
    filters,
  });

  const visitorsPerBreakdown = getBreakdownAnalyticValue({
    analytics,
    analyticValue: 'visitors',
    stage,
    substageId,
    stageBreakdownFilter,
  });
  const pageviewsPerBreakdown = getBreakdownAnalyticValue({
    analytics,
    analyticValue: 'pageviews',
    stage,
    substageId,
    stageBreakdownFilter,
  });

  return pageviewsPerBreakdown
    .map((entry) => {
      const visitorPerEntry = visitorsPerBreakdown.find((visitorItem) => visitorItem[stageBreakdownFilter] === entry[stageBreakdownFilter]) || {
        visitors: 0,
      };
      const conversionsPerEntry = conversionsPerBreakdown.find((convItem) => convItem[stageBreakdownFilter] === entry[stageBreakdownFilter]) || {
        count: 0,
        value: 0,
      };

      return {
        ...entry,
        count: conversionsPerEntry.count,
        visitors: visitorPerEntry.visitors,
        conversionRate: conversionsPerEntry.count / visitorPerEntry.visitors || 0,
        conversionValue: conversionsPerEntry.value,
        pageviews: entry.pageviews || 0,
      };
    })
    .sort((a, b) => b.visitors - a.visitors);
};

const getDefaultVisitors = ({ analytics, stage }) =>
  getAnalyticValue({
    analytics,
    analyticValueName: 'visitors_per_stage',
    channel: stage,
    channelIdName: 'stageId',
    childrenName: 'substages',
    childId: stage.substages[stage.substages.length - 1].stageId,
    resultValueName: 'visitors',
  });

const getDefaultPercentage = ({ analytics, stage, filters = [] }) => {
  const convDefault = getStageConversions({
    analytics,
    stage,
    filters,
    childId: stage.substages[stage.substages.length - 1].stageId,
  });
  const visitorsDefault = getDefaultVisitors({ analytics, stage });
  return convDefault.total / visitorsDefault;
};

export default {
  calculateTable({ stagesMap, journeys, analytics, filters, stageBreakdownFilter, onlyABTesting }) {
    return stagesMap
      .filter((stage) => stage.journeyId && stage.substages && stage.tree)
      .filter((stage) => {
        const isABStage = stage.tree[stage.tree.length - 1].name === 'A/B Testing';
        return isABStage === onlyABTesting;
      })
      .flatMap((stage) => {
        const stageJourney = journeys.find((entry) => entry._id === stage.journeyId);
        if (!stageJourney) return null;

        const stagesVisitors = getAnalyticValue({
          analytics,
          analyticValueName: 'visitors_per_stage',
          channel: stage,
          channelIdName: 'stageId',
          childrenName: 'substages',
          resultValueName: 'visitors',
        });

        const stagePageviews = getAnalyticValue({
          analytics,
          analyticValueName: 'pageviews_per_stage',
          channel: stage,
          channelIdName: 'stageId',
          childrenName: 'substages',
          resultValueName: 'pageviews',
        });

        const stageConversions = getStageConversions({
          analytics,
          stage,
          filters,
        });

        const stageConversionRate = stageConversions.total / stagesVisitors;
        const convPercentageDefault = getDefaultPercentage({
          analytics,
          stage,
          filters,
        });
        const visitorsDefault = getDefaultVisitors({ analytics, stage });

        return {
          journeyName: stageJourney.name,
          journeyId: stageJourney._id,
          label: stage.tree[stage.tree.length - 1].name,
          icon: stage.icon,
          visitors: stagesVisitors,
          pageviews: stagePageviews,
          conversions: stageConversions.total,
          conversionRate: stageConversionRate,
          conversionValue: stageConversions.value,
          visitorsNeeded: Math.ceil(calcSampleSize(convPercentageDefault)),
          substages: stage.substages.map((substage) => {
            const substageName = getSubstageName(substage.stageId, stage.substages);

            const substagesVisitors = getAnalyticValue({
              analytics,
              analyticValueName: 'visitors_per_stage',
              channel: stage,
              channelIdName: 'stageId',
              childrenName: 'substages',
              childId: substage.stageId,
              resultValueName: 'visitors',
            });

            const substagePageviews = getAnalyticValue({
              analytics,
              analyticValueName: 'pageviews_per_stage',
              channel: stage,
              channelIdName: 'stageId',
              childrenName: 'substages',
              childId: substage.stageId,
              resultValueName: 'pageviews',
            });

            const substageConversions = getStageConversions({
              analytics,
              stage,
              filters,
              childId: substage.stageId,
            });

            const substageConversionRate = substageConversions.total / substagesVisitors;
            const variationIndex = getVariationIndex(substage.stageId, stage.substages);

            return {
              stageId: substage.stageId,
              name: substageName,
              tree: stage.tree.map((v) => v.name),
              capital: String.fromCharCode(65 + (variationIndex % 26)),
              color: COLORS[variationIndex],
              visitors: substagesVisitors,
              conversions: substageConversions.total,
              conversionRate: substageConversionRate,
              conversionValue: substageConversions.value,
              pageviews: substagePageviews,
              signiPercentage: statisticPercentage(convPercentageDefault, substageConversionRate, visitorsDefault, substagesVisitors),
              breakdowns: getStageBreakdowns({
                analytics,
                stage,
                substageId: substage.stageId,
                stageBreakdownFilter,
                filters,
              }),
            };
          }),
        };
      });
  },
  calculateWidgets({ table, filters, variations }) {
    return table.map((journey) => ({
      key: journey.journeyName + new Date().valueOf(),
      name: `${journey.journeyName}: ${journey.label}`,
      value: journey.conversions,
      format: 'percent',
      chart: createChart(formatConversionsForChart(variations.conversions_per_stage, filters), variations.visitors_per_stage, journey.substages),
      colors: COLORS.slice(0, journey.substages.length),
    }));
  },
};
