import axios from '@services/api/axios';

export const fetchInterests = async () => {
  const response = await axios.get('/scraper/interests');

  return response.data;
};

export const createInterest = async ({ tags, conditions }) => {
  const response = await axios.post('/scraper/interests', {
    conditions,
    tags,
  });

  return response.data;
};

export const updateInterest = async (id, { tags, conditions }) => {
  const response = await axios.put(`/scraper/interests/${id}`, {
    conditions,
    tags,
  });

  return response.data;
};

export const removeInterest = async (id) => {
  const response = await axios.delete(`/scraper/interests/${id}`);

  return response.data;
};
