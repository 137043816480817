export const handleContactSalesMail = () => {
  const recipients = ['bart@taglayer.com', 'willem@taglayer.com'];
  const subject = encodeURIComponent('Sign up - Custom Plan');
  const body = encodeURIComponent(`Dear Taglayer,
    I'm writing to request a personalized account plan for my account. 

    Kind regards
    [Your Name]`);
  const mailtoLink = `mailto:${recipients.join(',')}?subject=${subject}&body=${body}`;
  // Click
  window.location.href = mailtoLink;
};

export const handleContactSalesHubspot = () => {
  const hubspotMeetingLink = 'https://www.taglayer.com/book-your-sales-meeting/';

  window.open(hubspotMeetingLink, '_blank');
};
