export default [
  {
    path: '/unsubscribe/email',
    name: 'unsubscribe-email',
    component: () => import('./unsubscribe-email.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/unsubscribe/visitor',
    name: 'unsubscribe-visitor',
    component: () => import('./unsubscribe-visitor.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/unsubscribe/contact',
    name: 'unsubscribe-contact',
    component: () => import('./unsubscribe-contact.vue'),
    meta: {
      requiresAuth: false,
    },
  },
];
