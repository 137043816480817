import axios from '@services/api/axios';

export const fetch = async () => {
  const response = await axios.get('/domain');

  return response.data;
};

export const fetchCompanyDomains = async () => {
  const response = await axios.get('/domain/company');

  return response.data;
};

export const activate = async (payload) => {
  const response = await axios.post('/domain/switch', payload);

  return response.data;
};

export const update = async (payload) => {
  const response = await axios.put('/domain/update', payload);

  return response.data;
};

export const admin = async (payload) => {
  const response = await axios.put('/domain/admin', payload);
  return response.data;
};

export const testWebhook = async (payload) => {
  const response = await axios.post('/domain/test-webhook', payload);
  return response.data;
};

export const blockDomainTracking = async (payload) => {
  const response = await axios.put('/domain/block-tracking', payload);

  return response.data;
};

export const updateSubSettings = async (payload) => {
  const response = await axios.put('/domain/update-unsubscribe-settings', payload);
  return response.data;
};
export const getSubSettings = async (domain) => {
  const response = await axios.get(`/domain/unsubscribe-settings/${domain}`);
  return response.data;
};

export const create = async (payload) => {
  const response = await axios.post('/domain/create', payload);

  return response.data;
};

export const remove = async () => {
  const response = await axios.delete('/domain/delete');

  return response.data;
};

export const getEvents = async (page) => {
  const response = await axios.get(`/domain/events?page=${page}`);

  return response.data;
};

export const getLimits = async () => {
  const response = await axios.get('/domain/monthly/message');

  return response.data;
};
