import axios from '@services/api/axios';

const handleBasicGetCall = async (endPoint) => {
  const response = await axios.get(endPoint);
  return response.data;
};

export const getPricing = async () => handleBasicGetCall('/bill/pricing');

export const getEstimatedPageviews = async () => handleBasicGetCall('/analytics/monthly-pageviews');

export const getPaymentMethods = async () => handleBasicGetCall('/bill/methods');

export const getPaymentCards = async () => handleBasicGetCall('/bill/cards');

export const getInvoices = async () => handleBasicGetCall('/bill/invoice');

export const getInvoice = async (id) => handleBasicGetCall(`/bill/invoice/${id}`);

export const getCard = async () => handleBasicGetCall('/bill/card');

export const setupPayment = async (paymentDetails) => {
  const response = await axios.post('/bill/setup-payment', paymentDetails);
  return response.data;
};

export const updateCard = async (cardInfo) => {
  const response = await axios.put('/bill/subscription/update', cardInfo);
  return response.data;
};

export const createCard = async (cardInfo) => {
  const response = await axios.post('/bill/card/create', cardInfo);
  return response.data;
};

export const updatePricing = async (tiers) => {
  const response = await axios.put('/bill/pricing', tiers);
  return response.data;
};

export const deleteCard = async (mandateId) => {
  const response = await axios.delete(`/bill/card/delete/${mandateId}`);
  return response.data;
};

export const updateBillingInformation = async (billingInformation) => {
  const response = await axios.put('/bill/invoice-details', billingInformation);
  return response.data;
};
