import axios from '@services/api/axios';

export const fetchJourneys = async () => {
  const response = await axios.get('/website/journey');

  return response.data;
};

export const createJourney = async (payload) => {
  const response = await axios.post('/website/journey', payload);

  return response.data;
};

export const deleteJourney = async (payload) => {
  const response = await axios.delete(`/website/journey/${payload}`);

  return response.data;
};

export const updateJourney = async (payload) => {
  let body = {};
  if (payload.audience) {
    body = {
      name: payload.name,
      audience: payload.audience._id,
      stageRoot: payload.body ? payload.body.stageRoot : payload.stageRoot,
      enabled: payload.body ? payload.body.enabled : payload.enabled,
      testing: payload.body ? payload.body.testing : payload.testing,
      tags: payload.body ? payload.body.tags : payload.tags,
    };
  } else if (payload._journey.audience) {
    body = {
      name: payload._journey.name,
      audience: payload._journey.audience,
      stageRoot: payload._journey.stageRoot,
      enabled: payload._journey.enabled,
      testing: payload._journey.testing,
      tags: payload._journey.tags,
    };
  } else {
    body = payload;
  }

  const response = await axios.put(`/website/journey/${payload._id ? payload._id : payload}`, body);

  return response.data;
};

export const publishJourney = async (id) => {
  const response = await axios.post('/website/snippet/publish/journey', { journeys: [id] });
  return response.data;
};

export const fetchTree = async (id) => {
  const response = await axios.get(`/website/journey/tree/${id}`);

  return response.data;
};

export const fetchAllStages = async () => {
  const response = await axios.get('/website/journey/stage');

  return response.data;
};

export const getStageByID = async (id) => {
  const response = await axios.get(`/website/journey/stage/${id}`);
  return response.data;
};

export const fetchStageOptions = async () => {
  const response = await axios.get('/website/journey/stage-types');
  return response.data;
};

export const updateJourneyStage = async (id, payload) => {
  const response = await axios.put(`/website/journey/stage/${id}`, payload);

  return response.data;
};

export const createJourneyStage = async (payload) => {
  const response = await axios.post('/website/journey/stage', payload);

  return response.data;
};

export const createGhostTree = async (payload) => {
  const response = await axios.post('/website/journey/stage/ghost', payload);
  return response.data;
};
export const deleteJourneyStage = async (id, variation) => {
  const response = await axios.delete(`/website/journey/stage/${id}${variation !== null ? `?variation=${variation}` : ''}`);

  return response.data;
};
export const testStage = async (payload) => {
  const response = await axios.post('/website/journey/stage/test', payload);

  return response.data;
};
