export default [
  {
    path: '/auth',
    children: [
      {
        path: '',
        redirect: '/',
      },
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('./login/login-page.vue'),
        meta: {
          layout: 'authentication',
          requiresAuth: false,
        },
      },
      {
        path: 'password-forgot',
        name: 'auth-password-forgot',
        component: () => import('./password-forgot/password-forgot.vue'),
        meta: {
          layout: 'authentication',
          requiresAuth: false,
        },
      },
      {
        path: 'password-reset/:token',
        name: 'auth-password-reset',
        component: () => import('./password-reset/password-reset.vue'),
        meta: {
          layout: 'authentication',
          requiresAuth: false,
        },
      },
      {
        path: 'signup',
        name: 'auth-signup',
        component: () => import('./signup/signup-page.vue'),
        meta: {
          layout: 'isolated',
          requiresAuth: false,
        },
      },
      {
        path: 'verify/:token',
        name: 'auth-verify-email',
        component: () => import('./verify-email/verify-email.vue'),
        meta: {
          layout: 'isolated',
          requiresAuth: false,
        },
      },
      {
        path: 'complete/:invitationToken',
        name: 'invitation-complete',
        component: () => import('./invitation/invitation-complete.vue'),
        meta: {
          layout: 'isolated',
          requiresAuth: false,
        },
      },
    ],
  },
];
