export default [
  {
    path: 'domain',
    children: [
      {
        path: '',
        redirect: '/settings/domain/info',
      },
      {
        path: 'info',
        component: () => import('./info/info-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'admin',
        component: () => import('./admin/admin-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'subscription',
        component: () => import('./subscription/subscription-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'activity',
        component: () => import('./activity/activity-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'capture-data-types',
        component: () => import('./capture-data/capture-data-types.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'export-capture-data',
        component: () => import('./capture-data/capture-data.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
