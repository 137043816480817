import visitorHelpers from '@helpers/store/visitors';
import profileHelpers from '@helpers/store/profiles';

const _state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchVisitor(_, visitorId) {
    const visitor = await this.$api.website.visitor.fetchVisitor(visitorId);

    return visitor;
  },
  async fetchVisitorData({ rootState }, visitorId) {
    const data = await this.$api.website.visitor.fetchVisitorData(visitorId, {
      failed: rootState.user.data.taglayerAdministrator,
    });
    return visitorHelpers.formatVisitorData(data);
  },
  async fetchVisitorProfile({ rootState }, visitorId) {
    const data = await this.$api.website.visitor.fetchVisitorProfile(visitorId, {
      failed: rootState.user.data.taglayerAdministrator,
    });

    return profileHelpers.formatProfileData(data);
  },
  async fetchRecommendations(_, { visitor, collection, domain }) {
    const recommendations = await this.$api.website.visitor.fetchRecommendations({ visitor, collection, domain });

    return recommendations;
  },
  async unsubscribe(_, { all, visitorId, journeyId, stageId }) {
    await this.$api.website.visitor.unsubscribeVisitor({
      all,
      visitorId,
      ...(journeyId && stageId ? { journeyId, stageId } : {}),
    });
  },

  async fetchVisitorAudience(_, visitor) {
    const audienceIds = await this.$api.website.visitor.fetchVisitorAudience(visitor);
    return audienceIds;
  },

};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
