import { createApp } from 'vue';
import { dragscrollNext } from 'vue-dragscroll';
import TaglayerElements from '@taglayer/v-elements';
import '@taglayer/v-elements/dist/style.css';
import * as Sentry from '@sentry/vue';

import { defineRule } from 'vee-validate';
import { all as AllRules } from '@vee-validate/rules';

import TaglayerWebsiteEditor from '@taglayer/editor';
// Editor does not use prefix on its styles
// I'm guessing tailwind gets imported twice because of this causing each class to be applied twice
import '@taglayer/editor/dist/style.css';

import validators from '@utils/validators';

import app from './app/app.vue';
import router from './router';
import store from './store';

import './assets/styles/font.css';
import './assets/styles/style.css';
import './assets/styles/tailwind.css';

import socketPlugin from './plugins/websocket';

// Register all standard rules shipped with vee validate
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
// Register our custom rules
Object.keys(validators).forEach((customRule) => {
  defineRule(customRule, validators[customRule]);
});

const instance = createApp(app);

Sentry.init({
  app: instance,
  dsn: 'https://2cc09488ad68cb20edc7494ebabf1403@o1204436.ingest.us.sentry.io/4508722133008389',
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'localdevelopment',
  // This might not be necessary when Github integration is setup in Sentry.
  // Leaving it here so I only have one release while testing
  // eslint-disable-next-line no-undef
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
      },
    }),
    Sentry.browserProfilingIntegration(),
    // Might not need this
    Sentry.replayIntegration(),
  ],
  ignoreErrors: [
    'top.GLOBALS',
    'EBCallBackMessageReceived',
    'Script error.',
    '_avast_submit',
    'User is not authenticated',
  ],
  denyUrls: [
    /api\.hubspot\.com/i,

    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,

    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],

  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: import.meta.env.MODE === 'production' ? 0.3 : 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_BASE_URL, import.meta.env.VITE_API],

  // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#profilesSampleRate
  profilesSampleRate: 1.0,

  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.05,

  attachProps: true,
  attachStacktrace: true,
});

instance.directive('dragscroll', dragscrollNext);

instance.use(router);
instance.use(store);
instance.use(TaglayerElements);
instance.use(socketPlugin, { url: import.meta.env.VITE_API });
instance.use(TaglayerWebsiteEditor);

instance.mount('#app');
