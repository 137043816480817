import contactsRoutes from './contacts/contacts-routes';

export default [
  {
    path: '/audience',
    children: [
      {
        path: '',
        redirect: '/audience/overview',
      },
      {
        path: 'overview',
        name: 'audience-overview',
        component: () => import('./overview/audiences-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'overview/:id',
        name: 'audience-visitors',
        component: () => import('./visitors/audience-visitors.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'business-visits',
        component: () => import('./business-visits/business-visits.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'visitors/:id',
        name: 'visitor-detail',
        component: () => import('./visitors/detail/visitor-detail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      ...contactsRoutes,
    ],
  },
];
