const _state = {
  data: null,
  domains: [],
  team: [],
};

const getters = {
  domains: (state) => state.domains,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_DATA(state, company) {
    state.data = company;
  },
  SET_DOMAINS(state, domains) {
    state.domains = domains;
  },
  SET_TEAM(state, team) {
    state.team = team;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    const company = await this.$api.company.fetch();
    const domains = await this.$api.domain.fetchCompanyDomains();

    commit('SET_DATA', company);
    commit('SET_DOMAINS', domains);

    return company;
  },
  async fetchTeam({ commit }) {
    const team = await this.$api.user.fetchCompanyTeam();

    commit('SET_TEAM', team);

    return team;
  },
  async update({ dispatch }, data) {
    const company = await this.$api.company.update(data);

    await dispatch('fetch');

    return company;
  },
  async create({ dispatch }, data) {
    const company = await this.$api.company.create(data);

    await dispatch('fetch');

    return company;
  },
  async remove({ dispatch }) {
    const company = await this.$api.company.remove();

    await dispatch('fetch');

    return company;
  },
  async switch({ dispatch, rootGetters }, id) {
    const company = await this.$api.company.activate({ company: id });
    await dispatch('fetch');
    const firstDomain = rootGetters['company/domains'][0];
    await dispatch('domain/switch', firstDomain._id, { root: true });

    return company;
  },
  async fetchCompanyDomains({ commit }) {
    const domains = await this.$api.domain.fetchCompanyDomains();

    commit('SET_DOMAINS', domains);

    return domains;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
