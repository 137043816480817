export default [
  {
    path: 'website',
    children: [
      {
        path: '',
        redirect: '/channels/website/cases',
      },
      {
        path: 'cases',
        name: 'cases-overview',
        component: () => import('./cases/cases-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'editor/:case',
        name: 'case-editor',
        component: () => import('./editor/case-editor.vue'),
        meta: {
          layout: 'isolated',
          requiresAuth: true,
        },
      },
    ],
  },
];
