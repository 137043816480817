import profileHelpers from '@helpers/store/profiles';

const _state = {
  loading: false,
  loadingAmounts: false,
  list: [],
  amounts: [],
  types: [],
};

const _getters = {
  list: (state) => state.list,
  enabledTypes: (state, getters, rootState, rootGetters) => state.types.filter((item) => {
    const hasIntegrated = rootGetters['website/integrations/hasIntegration'](item.id);
    return item.static || hasIntegrated;
  }),
  disabledTypes: (state, getters, rootState, rootGetters) => state.types.filter((item) => {
    const hasIntegrated = rootGetters['website/integrations/hasIntegration'](item.id);
    return !item.static && !hasIntegrated;
  }),
  getSourceIcon: (state) => (type) => {
    const source = state.types.find((item) => item.id === type);
    return source?.icon ?? 'ModuleThree2';
  },
  getSourceType: (state) => (type) => {
    const source = state.types.find((item) => item.id === type);
    return source;
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LOADING: (state, status) => {
    state.loading = status;
  },
  SET_LOADING_AMOUNTS(state, loading) {
    state.loadingAmounts = loading;
  },
  SET_LIST: (state, sources) => {
    state.list = sources.map((item) => ({
      ...item,
      total: state.amounts.find((aud) => aud.sourceId === item?._id)?.total ?? 0,
      connected: state.amounts.find((aud) => aud.sourceId === item?._id)?.connected ?? 0,
    }));
  },
  SET_LIST_AMOUNTS(state, amounts) {
    state.amounts = amounts;
    state.list = state.list.map((item) => ({
      ...item,
      total: amounts.find((aud) => aud.sourceId === item?._id)?.total ?? 0,
      connected: amounts.find((aud) => aud.sourceId === item?._id)?.connected ?? 0,
    }));
  },
  SET_TYPES: (state, types) => {
    state.types = types;
  },
  UPDATE_SOURCE_IN_LIST(state, updatedSource) {
    const index = state.list.findIndex((item) => item._id === updatedSource._id);
    if (index !== -1) {
      state.list.splice(index, 1, updatedSource);
    }
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);

    const sources = await this.$api.website.datasource.fetchDataSources();
    commit('SET_LIST', sources);
    commit('SET_LOADING', false);
    return sources;
  },
  async fetchContactsAmounts({ commit }) {
    commit('SET_LOADING_AMOUNTS', true);
    const audiencesAmounts = await this.$api.website.datasource.fetchContactsAmounts();

    commit('SET_LIST_AMOUNTS', audiencesAmounts);
    commit('SET_LOADING_AMOUNTS', false);

    return audiencesAmounts;
  },
  async fetchTypes({ commit }) {
    const types = await this.$api.website.datasource.fetchDataSourceTypes();
    commit('SET_TYPES', types);
    return types;
  },

  async fetchSourceById({ commit }, id) {
    const source = await this.$api.website.datasource.fetchSourceById(id);
    commit('UPDATE_SOURCE_IN_LIST', source);
    return source;
  },

  async createDataSource({ dispatch }, payload) {
    const source = await this.$api.website.datasource.createDataSource(payload);
    dispatch('fetch');
    dispatch('fetchContactsAmounts');
    return source;
  },
  async sync(_, sourceId) {
    await this.$api.website.datasource.syncDataSource(sourceId);
  },
  async fetchSourceContacts({ commit }, { id, page }) {
    commit('SET_LOADING', true);
    const contacts = await this.$api.website.datasource.fetchSourceContacts(id, { page });
    commit('SET_LOADING', false);
    return contacts;
  },
  async fetchSourceContactById({ rootState }, id) {
    const data = await this.$api.website.datasource.fetchSourceContactById(id, {
      failed: rootState.user.data.taglayerAdministrator,
    });
    return profileHelpers.formatProfileData(data);
  },
  async sendContactEmail(_, { id, payload }) {
    await this.$api.website.datasource.sendContactEmail(id, payload);
  },
  async unsubscribe(_, contactId) {
    await this.$api.website.datasource.unsubscribeContact(contactId);
  },
  async unsubscribeByEmail(_, payload) {
    await this.$api.website.datasource.unsubscribeContactByEmail(payload);
  },
  async remove({ dispatch }, id) {
    const audience = await this.$api.website.datasource.removeDataSource(id);
    await dispatch('fetch');
    return audience;
  },
  async updateDataSourceSettings({ dispatch }, { id, payload }) {
    await this.$api.website.datasource.updateDataSource(id, payload);
    await dispatch('fetch');
  },

};

export default {
  namespaced: true,
  state: _state,
  getters: _getters,
  actions,
  mutations,
};
