import audienceRoutes from './audience/audience-routes';
import dashboardRoutes from './dashboard/dashboard-routes';
import authRoutes from './auth/auth-routes';
import channelsRoutes from './channels/channels-routes';
import helpRoutes from './help/help-routes';
import integrationsRoutes from './integrations/integrations-routes';
import journeysRoutes from './journeys/journeys-routes';
import mediaRoutes from './media/media-routes';
import performanceRoutes from './performance/performance-routes';
import settingsRoutes from './settings/settings-routes';
import unsubscribeRoutes from './unsubscribe/unsubscribe-routes';
import productRoutes from './products/product-routes';

const baseRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
];

export default [
  ...dashboardRoutes,
  ...audienceRoutes,
  ...authRoutes,
  ...channelsRoutes,
  ...helpRoutes,
  ...integrationsRoutes,
  ...journeysRoutes,
  ...mediaRoutes,
  ...performanceRoutes,
  ...settingsRoutes,
  ...unsubscribeRoutes,
  ...productRoutes,
  ...baseRoutes,
];
