export default [
  {
    path: '/help',
    children: [
      {
        path: '',
        redirect: '/help/documentation',
      },
      {
        path: 'tutorials',
        component: () => import('./tutorials-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'documentation',
        name: 'Documentation',
        component: () => import('./documentation-page.vue'),
        props: (router) => ({ create: !!router.query.create }),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('./contact-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
