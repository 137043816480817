import moment from 'moment';
import { tglyrNumber } from '@helpers/numbers/numbers.helper';
import conversionsHelper from '@helpers/store/conversions';
import conversionPredictionsHelper from '@helpers/store/conversion-predictions';
import conditionsHelper from '@helpers/store/conditions';
import casesHelper from '@helpers/store/cases';
import automaticEmailsHelper from '@helpers/store/emails/automatic';
import manualEmailsHelper from '@helpers/store/emails/manual';
import notificationsHelper from '@helpers/store/notifications';
import whatsappHelper from '@helpers/store/whatsapp';
import rfmsHelpers from '@helpers/store/rfm';

const _state = {
  loading: false,
  period: {
    start: moment().subtract(7, 'days').toDate(),
    end: moment().subtract(1, 'days').toDate(),
  },
  filters: [],
  stageBreakdownFilter: '',
  data: {},
  // dashboard empty state
  dashboard: {
    visitor: {
      pageviews: 0,
      conversions: 0,
      conversionRate: 0,
      impressions: 0,
    },
    sentOut: {
      email: 0,
      notification: 0,
    },
    performance: {
      case: null,
      email: null,
      notification: null,
      conversion: null,
    },
    business: [],
    aiSuggestions: {
      actions: [],
      total: 0,
    },
  },
  // Each performance page analytics data
  conversions: {
    table: [],
    widgets: [],
  },
  abtests: {
    table: [],
    widgets: [],
  },
  cases: {
    table: [],
    widgets: [],
  },
  automaticEmails: {
    table: [],
    widgets: [],
  },
  manualEmails: {
    table: [],
    widgets: [],
  },
  notifications: {
    table: [],
    widgets: [],
    extra: {},
  },
  whatsapp: {
    table: [],
    widgets: [],
  },
  rfms: {
    table: [],
    widgets: [],
  },
  conditions: {
    table: [],
    widgets: [],
  },
  predictions: {
    table: [],
    widgets: [],
  },
};

const getters = {
  loading: (state) => state.loading,
  period: (state) => state.period,
  filters: (state) => state.filters,
  stageBreakdownFilter: (state) => state.stageBreakdownFilter,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_PERIOD(state, period) {
    state.period = period;
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  },
  SET_STAGE_BREAKDOWN_FILTER: (state, selected) => {
    state.stageBreakdownFilter = selected;
  },
  SET_DATA: (state, { total, daily, dashboard }) => {
    state.data = { total, daily };
    state.dashboard = dashboard;
  },
  SET_TABLE: (state, { key, table }) => {
    state[`${key}`].table = table;
  },
  SET_WIDGETS: (state, { key, widgets }) => {
    state[`${key}`].widgets = widgets;
  },
  SET_EXTRA: (state, { key, extra }) => {
    state[`${key}`].extra = extra;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  setPeriod({ commit }, period) {
    commit('SET_PERIOD', period);
  },
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  async setStageBreakdownFilter({ dispatch, commit }, selected) {
    commit('SET_STAGE_BREAKDOWN_FILTER', selected);

    await dispatch('formatABTests');
    await dispatch('formatConditions');
    dispatch('formatConversions');
  },
  async fetch({ dispatch, commit, state }) {
    commit('SET_LOADING', true);
    const data = await this.$api.analytics.fetchAllAnalytics(state.period);
    const recommendedActions = await this.$api.analytics.fetchRecommendedActions();

    await commit('SET_DATA', {
      ...data,
      dashboard: {
        ...data.dashboard,
        aiSuggestions: recommendedActions,
      },
    });

    await dispatch('formatData');
    await commit('SET_LOADING', false);

    return data;
  },
  async formatData({ dispatch, state }) {
    if (!state.data.total) return;
    await Promise.all([
      dispatch('formatABTests'),
      dispatch('formatConditions'),
      dispatch('formatAutomaticEmails'),
      dispatch('formatManualEmails'),
      dispatch('formatNotifications'),
      dispatch('formatWhatsapp'),
      dispatch('formatRfms'),
    ]);
    dispatch('website/business/setData', state.data.total.business.business_domains, { root: true });
    dispatch('formatConversions');
    dispatch('formatCases', { deleted: false }); // initially start with not deleted cases
    dispatch('formatConversionPredictions');
  },
  formatConversions({ commit, state, rootGetters }) {
    if (!state.data.total) return null;
    const convs = rootGetters['website/conversions/conversions'];

    const table = conversionsHelper.calculateTable({
      conversions: convs,
      totalConversions: state.data.total.conversions.total_conversions,
      taglayerConversions: state.data.total.conversions.taglayer_conversions,
    });
    const widgets = conversionsHelper.calculateWidgets({
      conversions: convs,
      totalConversions: state.data.total.conversions.total_conversions,
      taglayerConversions: state.data.total.conversions.taglayer_conversions,
      dailyTotalConversions: state.data.daily.conversions.total_conversions,
      dailyTaglayerConversions: state.data.daily.conversions.taglayer_conversions,
    });

    commit('SET_TABLE', { key: 'conversions', table });
    commit('SET_WIDGETS', { key: 'conversions', widgets });
    return { table, widgets };
  },
  async formatBaseCondition({ state, rootState }, onlyABTesting) {
    const conditionStages = state.data.total.stages.visitors_per_stage
      .filter((stage) => stage.substages.filter((substg) => !/_(case|trigger)$/.test(substg.stageId) && substg.stageId.includes('_')).length > 0)
      .map((stage) => stage.stageId);
    const stagesMap = await this.$api.analytics.fetchJourneyTree({ stages: conditionStages });
    const journeys = rootState.website.journeys.list;

    const table = conditionsHelper.calculateTable({
      stagesMap,
      journeys,
      filters: state.filters,
      analytics: state.data.total.stages,
      stageBreakdownFilter: state.stageBreakdownFilter,
      onlyABTesting,
    });
    const widgets = conditionsHelper.calculateWidgets({
      table,
      filters: state.filters,
      variations: state.data.daily.stages,
    });
    return { table, widgets };
  },
  async formatConditions({ commit, dispatch }) {
    const { table, widgets } = await dispatch('formatBaseCondition', false);

    commit('SET_TABLE', { key: 'conditions', table });
    commit('SET_WIDGETS', { key: 'conditions', widgets });
    return { table, widgets };
  },
  async formatABTests({ commit, state, dispatch }) {
    if (!state.data.total) return null;

    const { table, widgets } = await dispatch('formatBaseCondition', true);

    commit('SET_TABLE', { key: 'abtests', table });
    commit('SET_WIDGETS', { key: 'abtests', widgets });
    return { table, widgets };
  },
  formatCases({ commit, state, rootGetters }, payload) {
    if (!state.data.total) return null;
    const convs = rootGetters['website/conversions/conversions'];

    const table = casesHelper.calculateTable({
      cases: rootGetters[`website/cases/${payload.deleted ? 'deletedList' : 'list'}`],
      analytics: state.data.total.cases,
      filters: state.filters,
    });
    const widgets = casesHelper.calculateWidgets({
      analytics: {
        total: state.data.total.cases,
        daily: state.data.daily.cases,
      },
      filters: state.filters,
      conversions: convs,
    });
    commit('SET_TABLE', { key: 'cases', table });
    commit('SET_WIDGETS', { key: 'cases', widgets });
    return { table, widgets };
  },
  async formatAutomaticEmails({ commit, state, rootState }) {
    if (!state.data.total) return null;
    // Delete everything sendGrid related if journeys gets added to performance/all api call
    const sendGridJourneys = await this.$api.analytics.fetchEmailAnalytics(state.period);
    const stagesMap = await this.$api.analytics.fetchJourneyTree({
      stages: sendGridJourneys.total.journeys.reduce((acc, journey) => [...acc, ...journey.stages.map((stage) => stage.stageId)], []),
    });
    const journeys = rootState.website.journeys.list;
    const table = automaticEmailsHelper.calculateTable({
      filters: state.filters,
      analytics: {
        journeys: sendGridJourneys.total.journeys,
        ...state.data.total.emails,
      },
      stagesMap,
      journeys,
    });
    const widgets = automaticEmailsHelper.calculateWidgets({
      filters: state.filters,
      table,
      analytics: {
        journeys: sendGridJourneys.daily.journeys,
        ...state.data.daily.emails,
      },
    });

    commit('SET_TABLE', { key: 'automaticEmails', table });
    commit('SET_WIDGETS', { key: 'automaticEmails', widgets });
    return { table, widgets };
  },
  async formatManualEmails({ commit, state, dispatch, rootState }) {
    if (!state.data.total) return null;
    if (!rootState.website.datasource.list.length) await dispatch('website/datasource/fetch', null, { root: true });
    if (!rootState.website.audience.list.length) await dispatch('website/audience/fetch', null, { root: true });

    const sendGridData = await this.$api.analytics.fetchEmailAnalytics(state.period);
    const table = manualEmailsHelper.calculateTable({
      filters: state.filters,
      analytics: {
        sendgrid: sendGridData.total,
        ...state.data.total.emails,
      },
      list: [
        ...rootState.website.datasource.list,
        ...rootState.website.audience.list.map((item) => ({ ...item, isAudience: true })),
      ],
    });
    const widgets = manualEmailsHelper.calculateWidgets({
      filters: state.filters,
      analytics: {
        sendgrid: sendGridData.daily,
        ...state.data.daily.emails,
      },
      table,
    });

    commit('SET_TABLE', { key: 'manualEmails', table });
    commit('SET_WIDGETS', { key: 'manualEmails', widgets });

    return { table, widgets };
  },
  async formatNotifications({ commit, state, rootState }) {
    if (!state.data.total) return null;
    const journeys = rootState.website.journeys.list;
    const stagesMap = await this.$api.analytics.fetchJourneyTree({
      stages: state.data.total.notifications.notification_impressions.flatMap((notification) => notification.stages.map((stage) => stage.stageId)),
    });
    // Create map first for all journeys
    const table = notificationsHelper.calculateTable({
      analytics: state.data.total.notifications,
      filters: state.filters,
      journeys,
      stagesMap,
    });
    const widgets = notificationsHelper.calculateWidgets({
      analytics: state.data.daily.notifications,
    });
    const extraData = {
      permissions_requested: tglyrNumber(
        state.data.total.notifications.allow_notification_impressions.reduce((acc, next) => acc + next.total, 0) || 0,
      ),
      notification_subscribers: tglyrNumber(state.data.total.notifications.notification_subscribers || 0),
    };
    commit('SET_TABLE', { key: 'notifications', table });
    commit('SET_WIDGETS', { key: 'notifications', widgets });
    commit('SET_EXTRA', { key: 'notifications', extra: extraData });
    return { table, widgets, extraData };
  },
  async formatWhatsapp({ commit }) {
    const table = whatsappHelper.calculateTable();
    const widgets = whatsappHelper.calculateWidgets();

    commit('SET_TABLE', { key: 'whatsapp', table });
    commit('SET_WIDGETS', { key: 'whatsapp', widgets });
    return { table, widgets };
  },
  async formatRfms({ commit, state, dispatch, rootGetters }) {
    if (!state.data.total) return null;
    if (!rootGetters['website/rfm/segments'].length) await dispatch('website/rfm/fetchWithoutFormat', null, { root: true });
    const table = rfmsHelpers.calculateTable({
      segments: rootGetters['website/rfm/segments'],
      analytics: state.data.total.rfms,
      filters: state.filters,
    });
    const widgets = rfmsHelpers.fillWidget(table);

    commit('SET_TABLE', { key: 'rfms', table });
    commit('SET_WIDGETS', { key: 'rfms', widgets });
    return { table, widgets };
  },
  async generateAnalytics({ dispatch }) {
    const response = await this.$api.analytics.generateAnalytics();
    dispatch('fetch');

    return response;
  },
  formatConversionPredictions({ commit, state }) {
    if (!state.data.total) return null;

    const table = conversionPredictionsHelper.calculateTable({
      analytics: state.data.total.predictions,
      filters: state.filters,
    });
    const widgets = conversionPredictionsHelper.calculateWidgets(table);

    commit('SET_TABLE', { key: 'predictions', table });
    commit('SET_WIDGETS', { key: 'predictions', widgets });
    return { table, widgets };
  },
  async deleteAllAnalytics({ dispatch }) {
    const response = await this.$api.analytics.deleteAllAnalytics();
    dispatch('fetch');
    return response;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  mutations,
  actions,
};
