const _state = {
  list: [],
  deletedList: [],
  casesActiveInJourneys: [],
  loading: false,
};

const getters = {
  list: (state) => state.list,
  deletedList: (state) => state.deletedList,
  tags: (state) => [...new Set(state.list.map((caseItem) => caseItem.tags).flat())],
  casesActiveInJourneys: (state) => state.casesActiveInJourneys,
  loading: (state) => state.loading,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_ALL_LISTS: (state, cases) => {
    state.list = cases.filter((caseItem) => caseItem.deleted === false);
    state.deletedList = cases.filter((caseItem) => caseItem.deleted === true);
  },
  SET_CASES_ACTIVE_IN_JOURNEYS: (state, casesActiveInJourneys) => {
    state.casesActiveInJourneys = casesActiveInJourneys;
  },
  SET_LOADING: (state, status) => {
    state.loading = status;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_LOADING', true);

    const cases = await this.$api.website.cases.fetchCases();
    commit('SET_ALL_LISTS', cases);
    await dispatch('fetchActiveCases');
    commit('SET_LOADING', false);
    return cases;
  },
  async fetchWithoutLoader({ commit }) {
    const cases = await this.$api.website.cases.fetchCases();
    commit('SET_ALL_LISTS', cases);
    return cases;
  },
  async getCaseByID(_, caseId) {
    const caseByID = await this.$api.website.cases.getCaseByID(caseId);
    return caseByID;
  },
  async fetchActiveCases({ commit }) {
    const cases = await this.$api.website.cases.fetchActiveCases();
    commit('SET_CASES_ACTIVE_IN_JOURNEYS', cases);
    return cases;
  },
  async updateCase({ dispatch }, payload) {
    const updateCase = await this.$api.website.cases.updateCase(payload);
    await dispatch('fetchWithoutLoader');
    return updateCase;
  },
  async createCase({ dispatch }, payload) {
    const createdCase = await this.$api.website.cases.createCase(payload);
    await dispatch('fetchWithoutLoader');
    return createdCase;
  },
  async removeCase({ dispatch }, caseID) {
    const removeCase = await this.$api.website.cases.removeCase(caseID);
    await dispatch('fetchWithoutLoader');
    return removeCase;
  },
  async publishCase({ dispatch }, payload) {
    const publishedCase = await this.$api.website.cases.publishCase(payload);
    await dispatch('fetchWithoutLoader');
    return publishedCase;
  },
  async duplicateCase({ dispatch }, caseId) {
    const duplicatedCase = await this.$api.website.cases.duplicateCase(caseId);
    await dispatch('fetchWithoutLoader');
    return duplicatedCase;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
