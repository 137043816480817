export default [
  {
    path: 'company',
    children: [
      {
        path: '',
        redirect: '/settings/company/info',
      },
      {
        path: 'domains',
        component: () => import('./domains/domains-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'info',
        component: () => import('./info/info-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'team',
        component: () => import('./team/team-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
