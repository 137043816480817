export const roles = {
  viewer: 'viewer',
  editor: 'editor',
  admin: 'admin',
  taglayeradmin: 'taglayeradmin',
};

export const roleHierarchy = {
  viewer: 0,
  editor: 1,
  admin: 2,
  taglayeradmin: 99,
};
