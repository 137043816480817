const urlRegex =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()\-+={}[\]|\\:;"'<>,.?/_àùéè§°çµ£€]).{8,}$/;
const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i;
const ipRegex = /(^(\d{1,3}\.){3}(\d{1,3})$)/;
// This is to seperately check password instead of everything at once
const hasNumRegex = /(?=.*[0-9])/;
const hasLowerCaseRegex = /(?=.*[a-z])/;
const hasUpperCaseRegex = /(?=.*[A-Z])/;
const hasSymbolRegex = /(?=[~`!@#$%^&*()\-+={}[\]|\\:;"'<>,.?/_àùéè§°çµ£€])/;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
const masterCardRegex1 = /^5[1-5][0-9]{14}$/;
const masterCardRegex2 = /^2[2-7][0-9]{14}$/;
const ibanRegex = /^67\d{14}$/;

export default {
  urlRegex,
  emailRegex,
  visaRegex,
  masterCardRegex1,
  masterCardRegex2,
  ibanRegex,
  domainRegex,
  ipRegex,
  passwordRegex,
  hasNumRegex,
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasSymbolRegex,
};
