const _state = {
  files: [],
  collections: [],
};

const getters = {
  files: (state) => state.files,
  collections: (state) => state.collections,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_FILES(state, files) {
    state.files = files;
  },
  SET_COLLECTIONS(state, collections) {
    state.collections = collections;
  },
};
/* eslint-enable no-param-reassign */

/*  eslint-disable no-useless-catch */
const actions = {
  async fetchFiles({ commit }) {
    const files = await this.$api.website.media.fetchFiles();

    commit('SET_FILES', files);

    return files;
  },
  async deleteFile({ dispatch }, id) {
    try {
      const response = await this.$api.website.media.deleteFile(id);

      await dispatch('fetchFiles');
      await dispatch('fetchCollections');

      return response;
    } catch (error) {
      throw error;
    }
  },
  async fetchCollections({ commit }) {
    const collections = await this.$api.website.media.fetchCollections();

    commit('SET_COLLECTIONS', collections);

    return collections;
  },
  async createCollection({ dispatch }, payload) {
    try {
      const response = await this.$api.website.media.createCollection(payload);

      await dispatch('fetchCollections');

      return response;
    } catch (error) {
      throw error;
    }
  },
  async removeFromCollection({ dispatch }, { fileId, collectionId }) {
    try {
      const response = await this.$api.website.media.removeFromCollection(fileId, collectionId);

      await dispatch('fetchCollections');

      return response;
    } catch (error) {
      throw error;
    }
  },
  async addFileToCollection({ dispatch }, { fileId, collectionId }) {
    try {
      const response = await this.$api.website.media.addFileToCollection(fileId, collectionId);

      await dispatch('fetchCollections');

      return response;
    } catch (error) {
      throw error;
    }
  },
  async deleteCollection({ dispatch }, id) {
    try {
      const response = await this.$api.website.media.deleteCollection(id);

      await dispatch('fetchCollections');

      return response;
    } catch (error) {
      throw error;
    }
  },
  async uploadImages({ dispatch }, payload) {
    // eslint-disable-next-line no-restricted-syntax
    for (const image of payload.images) {
      if (image === null) return;
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.$api.website.media.uploadImages(image, payload.collection);

        // eslint-disable-next-line no-await-in-loop
        await dispatch('fetchFiles');
        // eslint-disable-next-line no-await-in-loop
        await dispatch('fetchCollections');
      } catch (error) {
        throw error;
      }
    }
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
