import { inject } from 'vue';

export default () => {
  const $socket = inject('$socket');

  const connectSocket = () => {
    try {
      $socket.connect();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  const disconnectSocket = () => {
    try {
      $socket.disconnect();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  return {
    $socket,
    connectSocket,
    disconnectSocket,
  };
};
