import channelsRoutes from './channels/channels-routes';
import websiteRoutes from './website/website-routes';

export default [
  {
    path: '/performance',
    children: [
      {
        path: '',
        redirect: '/performance/channels',
      },
      ...channelsRoutes,
      ...websiteRoutes,
    ],
  },
];
