<template>
  <tglyr-modal
    position="top center"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #content>
      <div class="p-6">
        <tglyr-radio-group
          v-if="hasMultipleCompanies"
          v-model="searchType"
          size="base"
          class="mb-4 w-full gap-1"
          @update:model-value="selectedIndex = 0"
        >
          <tglyr-radio-button
            label="Domain"
            value="domain"
            hide-checkbox
          >
            <div class="flex items-center justify-center w-full h-full">
              <tglyr-icon
                icon-name="Earth1"
                custom-icon-class="h-4 w-4 mr-2"
              />
              <span class="font-medium">Domain</span>
            </div>
          </tglyr-radio-button>
          <tglyr-radio-button
            label="Company"
            value="company"
            hide-checkbox
          >
            <div class="flex items-center justify-center w-full h-full">
              <tglyr-icon
                icon-name="Buildings2"
                custom-icon-class="h-4 w-4 mr-2"
              />
              <span class="font-medium">Company</span>
            </div>
          </tglyr-radio-button>
        </tglyr-radio-group>

        <tglyr-input
          ref="input"
          :model-value="searchValue"
          :placeholder="`Switch to another ${searchType}`"
          type="text"
          autofocus
          @update:model-value="onUpdateSearch($event)"
        />

        <p class="flex items-center text-sm mt-3 text-gray-700">
          Press
          <span class="mx-1 py-1 text-base font-mono font-semibold px-1 rounded leading-none bg-gray-100">enter</span>
          to select,
          <span class="mx-1 py-1 text-base font-mono font-semibold px-1 rounded leading-none bg-gray-100">esc</span>
          to hide.
        </p>

        <div class="mt-6">
          <ul
            v-if="searchResults.length"
            class="mt-3 max-h-72 overflow-y-scroll"
          >
            <li
              v-for="(result, index) in searchResults"
              :key="result._id"
              ref="resultItems"
              class="px-4 py-3 font-semibold rounded-lg cursor-pointer transition-colors duration-100"
              :class="{ 'bg-blue-500 text-white shadow-md': index === selectedIndex }"
              @mouseover="selectedIndex = index"
              @click="submitSwitch(result._id)"
            >
              <div class="flex items-center gap-1">
                <tglyr-loading
                  v-if="index === selectedIndex && loading"
                  class="mr-2 shrink-0"
                  :size="16"
                  weight="bold"
                  color="#FFFFFF"
                />
                <img
                  v-if="searchType === 'company' && result.image"
                  :src="result.image"
                  loading="lazy"
                  class="h-6 w-6 rounded object-cover"
                >
                <tglyr-icon
                  v-else
                  :icon-name="searchType === 'domain' ? 'Earth1' : 'Buildings2'"
                  class="w-6 h-6"
                />
                <span>{{ searchType === 'domain' ? result.domain : result.name }}</span>
              </div>
            </li>
          </ul>
          <div
            v-else
            class="text-center pt-10 pb-6 text-gray-500"
          >
            <p>No matching {{ searchType }}s found.</p>
          </div>
        </div>
      </div>
    </template>
  </tglyr-modal>
</template>

<script setup>
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['update:visible', 'switch']);
const store = useStore();

const loading = ref(false);

const searchType = ref('domain');
const searchValue = ref('');
const resultItems = ref();

const selectedIndex = ref(0);

const hasMultipleCompanies = computed(() => store.state.user.companies.length > 1);
const searchResults = computed(() => {
  if (searchType.value === 'domain') {
    return store.state.company.domains.filter((domain) => domain.domain.toLowerCase().includes(searchValue.value.toLowerCase()));
  }

  return store.state.user.companies.filter((company) => company.name.toLowerCase().includes(searchValue.value.toLowerCase()));
});

const onUpdateSearch = _.throttle((newQuery) => {
  searchValue.value = newQuery;
}, 500);

const submitSwitch = async (id) => {
  loading.value = true;

  if (searchType.value === 'domain') {
    store.dispatch('domain/switch', id);
  } else {
    store.dispatch('company/switch', id);
  }
  emit('update:visible', false);

  searchValue.value = '';
  searchType.value = 'domain';
  selectedIndex.value = 0;
  loading.value = false;
};

const handleShortcut = async (e) => {
  const key = e.key || e.keyCode;

  const preventDefaultAndStopPropagation = () => {
    e.preventDefault();
    e.stopPropagation();
  };

  switch (key) {
    case 9: // Tab
    case 'Tab':
      preventDefaultAndStopPropagation();
      if (!hasMultipleCompanies.value) return;
      searchType.value = searchType.value === 'domain' ? 'company' : 'domain';
      selectedIndex.value = 0;
      break;

    case 38: // ArrowUp
    case 'ArrowUp':
      preventDefaultAndStopPropagation();
      if (selectedIndex.value > 0) {
        selectedIndex.value -= 1;
        resultItems.value[selectedIndex.value]?.scrollIntoViewIfNeeded({ behaviour: 'smooth' });
      }
      break;

    case 13: // Enter
    case 'Enter':
      preventDefaultAndStopPropagation();
      // eslint-disable-next-line no-case-declarations
      const selected = searchResults.value[selectedIndex.value];
      if (selected) await submitSwitch(selected._id);
      break;

    case 40: // ArrowDown
    case 'ArrowDown':
      preventDefaultAndStopPropagation();
      if (selectedIndex.value < searchResults.value.length - 1) {
        selectedIndex.value += 1;
        resultItems.value[selectedIndex.value]?.scrollIntoViewIfNeeded({ behaviour: 'smooth' });
      }
      break;

    case 'Escape':
    case 'Esc':
    case 27:
      preventDefaultAndStopPropagation();
      emit('update:visible', false);
      break;

    default:
      break;
  }
};

watch(
  () => props.visible,
  (v) => {
    if (v) document.addEventListener('keydown', handleShortcut);
    else document.removeEventListener('keydown', handleShortcut);
  },
);
</script>
