import axios from '@services/api/axios';

export const fetchTemplates = async () => {
  const response = await axios.get('/whatsapp/templates');
  return response.data;
};

export const fetchPhoneNumbers = async () => {
  const response = await axios.get('/whatsapp/senders');
  return response.data;
};
