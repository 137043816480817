import axios from '@services/api/axios';

const generateAnalytics = async () => {
  const response = await axios.post('/analytics/generate');

  return response.data;
};

const deleteAllAnalytics = async () => {
  const response = await axios.delete('/analytics/delete');
  return response.data;
};

const fetchAllAnalytics = ({ start, end }) => axios.get(`/analytics/all?range=${start},${end}`).then((res) => res.data);

const fetchCategoryAnalytics = (time, category) =>
  axios.get(`/analytics/category/${category}?range=${time.start},${time.end}`).then((res) => res.data);

const fetchEmailAnalytics = ({ start, end }) => axios.get(`/analytics/email?range=${start},${end}`).then((res) => res.data);

const fetchWhatsappAnalytics = ({ start, end }) => axios.get(`/analytics/whatsapp?range=${start},${end}`).then((res) => res.data);

const fetchDashboardAnalytics = ({ start, end }) => axios.get(`/analytics/dashboard?range=${start},${end}`).then((res) => res.data);

const fetchJourneyTree = (stages) => axios.post('/website/journey/stage/tree', stages).then((res) => res.data);

const fetchRecommendedActions = () => axios.get('/analytics/actions').then(((res) => res.data));

export {
  deleteAllAnalytics,
  fetchRecommendedActions,
  fetchCategoryAnalytics,
  fetchDashboardAnalytics,
  generateAnalytics,
  fetchAllAnalytics,
  fetchEmailAnalytics,
  fetchWhatsappAnalytics,
  fetchJourneyTree,
};
