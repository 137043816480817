import axios from '@services/api/axios';

export const fetchCollections = async () => {
  const response = await axios.get('/scraper/collection');

  return response.data;
};

export const createCollection = async (payload) => {
  const response = await axios.post('/scraper/collection', payload);

  return response.data;
};

export const fetchFeeds = async () => {
  const response = await axios.get('/scraper/feed');

  return response.data;
};

export const createFeed = async (payload) => {
  const response = await axios.post('/scraper/feed', payload);
  return response.data;
};

export const fetchFeedData = async (payload) => {
  const response = await axios.post('/scraper/feed/fetch', payload);

  return response.data;
};

export const removeFeed = async (id) => {
  const response = await axios.delete(`/scraper/feed/${id}`);

  return response;
};

export const updateFeed = async ({ id, payload }) => {
  const response = await axios.put(`/scraper/feed/${id}`, payload);

  return response.data;
};

export const fetchFeedFieldTypes = async () => {
  const response = await axios.get('/scraper/template/field/types');

  return response.data;
};

export const fetchScraperTemplateFields = async (id) => {
  const response = await axios.get(`/scraper/template/fields/${id}`);

  return response.data;
};

export const createScraperTemplate = async (payload) => {
  const response = await axios.post('/scraper/template', payload);

  return response.data;
};

export const updateScraperTemplate = async ({ id, payload }) => {
  const response = await axios.put(`/scraper/template/${id}`, payload);

  return response.data;
};

export const fetchTags = async (query) => {
  const response = await axios.get(`/scraper/page/tags?q=${query ?? ''}`);
  return response.data;
};

export const fetchPages = async ({ collection, page, active }) => {
  const query = new URLSearchParams({ collection, page, active });

  const response = await axios.get(`/scraper/page?${query}`);

  return response.data;
};

export const fetchProduct = async ({ collection, url }) => {
  const response = await axios.get(`/scraper/page/url?url=${encodeURIComponent(url)}&collectionId=${collection}`);
  return response.data;
};

export const searchPages = async ({ query, page }) => {
  const response = await axios.get(`/scraper/page/search?q=${query}&page=${page}`);

  return response.data;
};

export const createPage = async (body) => {
  const response = await axios.post('/scraper/page', body);

  return response.data;
};

export const updatePage = async (id, body) => {
  const response = await axios.put(`/scraper/page/${id}`, body);

  return response.data;
};

export const removePage = async (id) => {
  const response = await axios.delete(`/scraper/page/${id}`);

  return response.data;
};

export const triggerFeeds = async () => {
  const response = await axios.get('/scraper/feed/trigger');

  return response.data;
};
