<template>
  <div
    class="p-2 flex gap-8 items-center w-full cursor-pointer rounded truncate transition-all ease-linear duration-300 menuHoverShadow"
    :class="
      active ? 'bg-blue-50 shadow-inner mouse-cursor-gradient-tracking' : 'hover:bg-gray-50 hover:shadow-inner mouse-cursor-gradient-tracking-hover'
    "
    @click="handleClick"
    @mousemove="handleHover"
  >
    <tglyr-avatar
      v-if="avatar"
      class="rounded shrink-0 hover:opacity-75"
      size="1.75rem"
      :image="icon"
      :name="name"
    />
    <tglyr-icon
      v-else
      :icon-name="icon"
      :custom-icon-class="['h-5 w-5', active ? 'text-blue-500' : 'text-gray-500']"
    />
    <div class="truncate flex flex-row justify-between w-full">
      <span
        :class="active ? 'font-semibold text-blue-500' : 'font-medium text-gray-600'"
        class="text-sm"
      >
        {{ name }}
      </span>
      <tglyr-icon
        v-if="hasChildren"
        icon-name="ArrowRight1"
        :custom-icon-class="['h-2 w-2', active ? 'text-blue-500' : 'text-gray-500']"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { handleHover } from '../../composables/nav';

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
  avatar: {
    type: Boolean,
    default: false,
  },
  hasChildren: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const store = useStore();

const handleClick = async () => {
  await store.dispatch('website/onboarding/setOpenState', false);
  if (props.to) router.push({ path: props.to }).catch(() => {});
};
</script>
