const _state = {
  data: null,
  companies: [],
};

const getters = {};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_DATA(state, user) {
    state.data = user;
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    const user = await this.$api.user.fetch();
    const companies = await this.$api.company.fetchUserCompanies();

    commit('SET_DATA', user);
    commit('SET_COMPANIES', companies);

    return user;
  },
  async fetchUserCompanies({ commit }) {
    const companies = await this.$api.company.fetchUserCompanies();

    commit('SET_COMPANIES', companies);

    return companies;
  },
  async update({ dispatch }, data) {
    const user = await this.$api.user.update(data);

    dispatch('fetch');

    return user;
  },
  async updatePassword({ dispatch }, data) {
    const user = await this.$api.user.updatePassword(data);

    dispatch('fetch');

    return user;
  },
  async remove({ dispatch }) {
    const user = await this.$api.user.remove();

    dispatch('fetch');

    return user;
  },
  async fetchTwoFactorSetup() {
    const { otp } = await this.$api.user.fetchTwoFactorSetup();

    return otp;
  },
  async enableTwoFactor({ dispatch }, data) {
    await this.$api.user.enableTwoFactor(data);

    dispatch('fetch');
  },
  async disableTwoFactor({ dispatch }) {
    await this.$api.user.disableTwoFactor();

    dispatch('fetch');
  },
  async invite({ dispatch }, data) {
    const invited = await this.$api.user.invite(data);

    await dispatch('company/fetchTeam', null, { root: true });

    return invited;
  },
  async resendInvitation(_, data) {
    const res = await this.$api.user.resendInvitation(data);

    return res;
  },
  async resendVerification(_, data) {
    const res = await this.$api.user.resendVerification(data);
    return res;
  },
  async completeInvite({ dispatch }, data) {
    const invitedCompleteUser = await this.$api.user.completeInvite(data);

    dispatch('fetch');

    return invitedCompleteUser;
  },
  async updateUserRole({ dispatch }, data) {
    const response = await this.$api.user.updateUserRole(data);

    await dispatch('company/fetchTeam', null, { root: true });

    return response;
  },
  async deleteUserFromTeam({ dispatch }, id) {
    const response = await this.$api.user.deleteUserFromTeam(id);

    await dispatch('company/fetchTeam', null, { root: true });

    return response;
  },
  async addEmailReport(_, payload) {
    const response = await this.$api.user.addEmailReport(payload);

    return response;
  },
  async deleteEmailReport(_, payload) {
    const response = await this.$api.user.deleteEmailReport(payload);

    return response;
  },
  async sendPasswordReset(_, email) {
    await this.$api.user.sendPasswordReset(email);
  },
  async savePasswordReset(_, { password, token }) {
    await this.$api.user.savePasswordReset({ password, token });
  },
  async verifyEmail(_, { token }) {
    await this.$api.user.verifyEmail({ token });
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
