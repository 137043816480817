import utils from '../utils';

export default {
  formatProfileData(data) {
    if (!data) return { profile: null, messages: [] };

    return {
      profile: data.profile,
      contactLists: data.contactLists,
      messages: utils.formatMessageData(data.messages),
      external: [
        ...utils.formatExternalData(data.external?.conversions ?? []),
        ...utils.formatExternalData(data.external?.events ?? []),
      ],
    };
  },
};
