const _state = {
  loading: false,
  list: [],
};

const getters = {
  loading: (state) => state.loading,
  conversions: (state) => state.list,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LIST(state, conversions) {
    state.list = conversions;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_LOADING', true);
    const conversions = await this.$api.website.conversions.fetchConversions();
    commit('SET_LIST', conversions);

    // recalculate performance data for conversions (e.g. new conversions created)
    dispatch('analytics/formatConversions', {}, { root: true });

    commit('SET_LOADING', false);

    return conversions;
  },
  async createConversion({ dispatch }, conversion) {
    await this.$api.website.conversions.createConversion(conversion);

    await dispatch('fetch');
  },
  async updateConversion({ dispatch }, { id, conversion }) {
    await this.$api.website.conversions.updateConversion(id, conversion);

    await dispatch('fetch');
  },
  async removeConversion({ dispatch }, id) {
    await this.$api.website.conversions.removeConversion(id);

    await dispatch('fetch');
  },
};

export default {
  state: _state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
