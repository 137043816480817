import { inject } from 'vue';

const useNotify = () => {
  const $notify = inject('$notify');

  const notifySuccess = (text) => {
    $notify({
      title: 'Success',
      text,
      type: 'success',
    });
  };

  const notifyError = (text) => {
    $notify({
      title: 'Error',
      text,
      type: 'danger',
    });
  };

  const notifyWarning = (text) => {
    $notify({
      title: 'Warning',
      text,
      type: 'warning',
    });
  };

  return {
    $notify,
    notifySuccess,
    notifyError,
    notifyWarning,
  };
};

export default useNotify;
