import { tglyrPrice } from '@helpers/numbers/numbers.helper';
import moment from 'moment';
import { calculateConversions } from '../main';

const calculateAudienceConversions = (filters, analytics, audienceItem) => calculateConversions({
  analytics,
  analyticsValueName: 'conversions_per_email_audience',
  channel: audienceItem,
  channelIdName: 'audienceId',
  filters,
  conversionChild: 'audiences',
});

const calculateCaptureContactsConversions = (filters, analytics, captureListItem) => calculateConversions({
  analytics,
  analyticsValueName: 'conversions_per_email_contactlist',
  channel: captureListItem,
  channelIdName: 'contactlistId',
  filters,
  conversionChild: 'contactlists',
});

const calculateTotalData = (items, key) => items.reduce((acc, item) => {
  const total = item.data?.reduce((prev, next) => prev + next.data[key.toLowerCase()], 0);
  acc.push(total);
  return acc;
}, []);

const createDailyChartData = (key, analytics) => {
  const audienceData = calculateTotalData(analytics.audiences, key);
  const contactListData = calculateTotalData(analytics.contactlists, key);

  return {
    name: key.replaceAll('_', ' '),
    data: audienceData.map((entry, index) => entry + contactListData[index]),
  };
};

const calculateTotalConversionData = (conversionsData, filters) => conversionsData.reduce((acc, conversion) => {
  const filtered = conversion.data.filter((conv) => (filters.length < 1 ? true : filters.indexOf(conv.conversionId) > -1));
  const total = filtered.reduce((prev, next) => prev + next.total, 0);
  const value = filtered.reduce((prev, next) => prev + next.value, 0);
  acc.push({ total, value });
  return acc;
}, []);

const createDailyConversionChart = (filters, name, analytics) => {
  const audienceData = calculateTotalConversionData(analytics.conversions_per_email_audience, filters);
  const contactListData = calculateTotalConversionData(analytics.conversions_per_email_contactlist, filters);

  return [
    {
      name,
      data: audienceData.map((entry, index) => entry.total + (contactListData[index]?.total || 0)),
    },
    // Can't add valuta here since it will break widget. Need to create a prop for widget that accepts valuta and parses it into the tooltip
    // {
    //   name: `${name} value`,
    //   data: audienceData.map((entry, index) => entry.value + (contactListData[index]?.value || 0)),
    // },

  ];
};

export default {
  calculateTable({ filters, analytics, list }) {
    const createTableData = (filterCondition, conversionCalculator) => {
      const filteredList = list.filter(filterCondition);
      return filteredList.reduce((acc, item) => {
        const conversions = conversionCalculator(filters, analytics, item);
        const foundSendgridData = item.isAudience
          ? analytics.sendgrid.audiences.find((entry) => entry.audienceId === item._id)
          : analytics.sendgrid.contactlists.find((entry) => entry.contactId === item._id);
        if (foundSendgridData) {
          acc.push({
            ...foundSendgridData,
            name: !item.csvName ? item.name : item.csvName,
            isAudience: !item.csvName,
            conversions: conversions.total,
            conversionsValue: conversions.value,
          });
        }
        return acc;
      }, []);
    };
    const audienceTableData = createTableData(
      (entry) => entry?.isAudience,
      calculateAudienceConversions,
    );

    const contactListTableData = createTableData(
      (entry) => !entry?.isAudience,
      calculateCaptureContactsConversions,
    );
    return [...audienceTableData, ...contactListTableData];
  },
  calculateWidgets({ filters, table, analytics }) {
    const conversionValue = table.reduce((sum, next) => sum + (next?.conversionsValue || 0), 0);

    return [
      {
        key: 'manual delivered',
        name: 'Total emails delivered',
        value: table.reduce((sum, next) => sum + (next?.delivered || 0), 0),
        chart: {
          xasCategories: analytics.conversions_per_email_audience.map((day) => moment(day.date).format('D MMM YYYY')),
          series: [createDailyChartData('Delivered', analytics.sendgrid)],
        },
        colors: ['#f97316'],
        format: 'number',
      },
      {
        key: 'manual unique_opens',
        name: 'Unique opens',
        value: table.reduce((sum, next) => sum + (next?.unique_opens || 0), 0),
        chart: {
          xasCategories: analytics.conversions_per_email_audience.map((day) => moment(day.date).format('D MMM YYYY')),
          series: [createDailyChartData('Unique_opens', analytics.sendgrid)],
        },
        colors: ['#f97316'],
        format: 'number',
      },
      {
        key: 'manual conversions',
        name: 'Total conversions',
        value: table.reduce((sum, next) => sum + (next?.conversions || 0), 0),
        tags: conversionValue > 0 ? [
          {
            type: 'success',
            text: tglyrPrice(conversionValue),
          },
        ] : [],
        chart: {
          xasCategories: analytics.conversions_per_email_audience.map((day) => moment(day.date).format('D MMM YYYY')),
          series: createDailyConversionChart(filters, 'Conversions', analytics),
        },
        colors: ['#f97316', '#311704'],
        format: 'number',
      },
    ];
  },
};
