import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { roles, roleHierarchy } from './rolesConfig';

const useUserRoles = () => {
  const store = useStore();

  const $notify = inject('$notify');

  const roleIcons = {
    viewer: 'SingleNeutralActionsView',
    editor: 'SingleNeutralActionsEdit1',
    admin: 'SingleNeutralActionsKey',
  };
  const features = [
    'View journeys and audiences/visitors',
    'Edit cases/journeys/audiences',
    'Publish cases/journeys/audiences',
    'Add/delete integrations',
    'Invite/delete users',
  ];

  const roleFeaturePermissions = [
    { role: 'viewer', permissions: [true, false, false, false, false] },
    { role: 'editor', permissions: [true, true, true, false, false] },
    { role: 'admin', permissions: [true, true, true, true, true] },
  ];

  const company = computed(() => store.getters['auth/company']);
  const owner = computed(() => store.getters['auth/company'].owner);
  const user = computed(() => store.state.user);
  const currentUserRole = computed(() => {
    if (user.value.data.taglayerAdministrator) return 'taglayeradmin';
    return user.value.data.clients.find(({ client }) => String(client) === String(company.value._id))?.role;
  });

  const hasPermission = (requiredRole, userRole) => {
    const userRoleHierarchy = roleHierarchy[userRole];
    const requiredRoleHierarchy = roleHierarchy[requiredRole];

    return userRoleHierarchy >= requiredRoleHierarchy;
  };

  const roleOfUserFromCurrentCompany = (userToCheckRole) => userToCheckRole.clients.find(({ client }) => String(client) === String(company.value._id))?.role;

  const withRoleCheck = (requiredRole, func) => (...args) => {
    if (hasPermission(requiredRole, currentUserRole.value)) {
      func(...args);
    } else {
      $notify({
        title: 'Blocked',
        text: `${currentUserRole.value}s are not allowed to do this`,
        type: 'danger',
      });
    }
  };

  return {
    currentUserRole,
    roles,
    roleIcons,
    features,
    roleFeaturePermissions,
    hasPermission,
    withRoleCheck,
    roleOfUserFromCurrentCompany,
    owner,
  };
};

export default useUserRoles;
