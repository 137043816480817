export default [
  {
    path: 'email',
    children: [
      {
        path: '',
        redirect: '/channels/email/templates',
      },
      {
        path: 'templates',
        component: () => import('./templates/templates-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'builder/:email',
        name: 'email-builder',
        component: () => import('./builder/email-builder.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
