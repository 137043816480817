import { getAnalyticValue, calculateConversions } from '../main';

const groups = [
  { group: '0-10' },
  { group: '10-20' },
  { group: '20-30' },
  { group: '30-40' },
  { group: '40-50' },
  { group: '50-60' },
  { group: '60-70' },
  { group: '70-80' },
  { group: '80-90' },
  { group: '90-100' },
];

export default {
  calculateTable({ analytics, filters }) {
    return groups.map((group) => {
      const visitors = getAnalyticValue({
        analytics,
        analyticValueName: 'visitors_per_predictionscore',
        channel: group,
        channelIdName: 'group',
        resultValueName: 'total',
      });
      const pageviews = getAnalyticValue({
        analytics,
        analyticValueName: 'conversion_prediction_groups',
        channel: group,
        channelIdName: 'group',
        resultValueName: 'total',
      });
      const conversionValue = calculateConversions({
        analytics,
        analyticsValueName: 'conversions_per_predictionscore',
        channel: group,
        channelIdName: 'group',
        filters,
        conversionChild: 'groups',
      });
      return {
        ...group,
        visitors,
        pageviews,
        conversions: conversionValue.total,
        conversionRate: Number.isFinite(conversionValue.total / visitors) ? conversionValue.total / visitors : 0,
        conversionValue: conversionValue.value,
      };
    });
  },
  calculateWidgets(table) {
    return {
      key: 'visitors',
      name: 'Total visitors',
      chart: {
        xasCategories: groups.map((group) => `${group.group}%`),
        series: [{ name: 'Visitors', data: table.map((entry) => entry.visitors) }],
      },
      value: table.reduce((acc, next) => acc + next.visitors, 0),
      format: 'number',
      type: 'bar',
      colors: [
        '#4389fc',
        '#ffaa6e',
        '#62bdb0',
        '#9780fd',
        '#f96d7a',
        '#b450fc',
        '#ffe866',
        '#dd5394',
        '#998a8a',
        '#D2B48C',
        '#47ffff',
        '#70ff70',
        '#636363',
        '#b7fc56',
        '#f7754d',
      ],
      grid: true,
    };
  },
};
