<template>
  <section class="flex flex-col gap-6 h-full w-full overflow-y-scroll overflow-x-hidden">
    <div
      v-for="group in groups"
      :key="group.name"
    >
      <div class="flex flex-col">
        <span
          v-if="group.name && group.name !== 'none'"
          class="text-sm px-4 text-gray-600 font-bold uppercase"
        >
          {{ group.name }}
        </span>
        <section class="flex flex-col gap-2 mt-3">
          <div
            v-for="link in group.items"
            :key="link.id"
          >
            <div
              :id="link.id"
              class="block px-4 py-2 text-sm rounded transition-all duration-300 truncate cursor-pointer w-full"
              :class="{
                'font-semibold text-red-500 hover:bg-red-50 hover:shadow-inner': link.logoutRoute,
                'font-semibold text-blue-500 bg-blue-50 shadow-inner mouse-cursor-gradient-tracking menuHoverShadow': active && active.to === link.to,
                'font-medium text-gray-600 hover:bg-gray-50 hover:shadow-inner mouse-cursor-gradient-tracking-hover menuHoverShadow':
                  active && active.to !== link.to,
              }"
              :data-test="`navigation-${link.id.toLowerCase()}`"
              @click="handleClick(link.to, link.logoutRoute)"
              @mousemove="handleHover"
            >
              {{ link.text }}
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject, defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useUserRoles from '@composables/user-roles';
import useSocket from '@composables/socket';
import { handleHover } from '@composables/nav';

const socket = useSocket();
const { roles, currentUserRole, hasPermission } = useUserRoles();

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  active: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['clicked']);

const store = useStore();
const router = useRouter();
const $confirm = inject('$confirm');

const groups = computed(() =>
  props.items.reduce((acc, item) => {
    if (item.adminOnly && !hasPermission(roles.taglayeradmin, currentUserRole)) return acc;
    const groupName = item.group || 'none';

    const groupIndex = acc.findIndex((group) => group.name === groupName);
    if (groupIndex !== -1) acc[groupIndex].items.push(item);
    else acc.push({ name: groupName, items: [item] });

    return acc;
  }, []));

const handleClick = async (path, isLogoutRoute) => {
  if (isLogoutRoute) {
    $confirm({
      title: 'Logout?',
      message: 'Are you sure you want to exit Taglayer?',
      type: 'danger',
      callback: async () => {
        await store.dispatch('auth/logout');
        socket.disconnectSocket();
        router.push('/auth/login');
      },
    });
  } else {
    router.push({ path }).catch(() => {});
    emit('clicked');
  }
};
</script>
