import audience from './audience';
import journeys from './journeys';
import integrations from './integrations';
import whatsapp from './whatsapp';
import cases from './cases';
import conversions from './conversions';
import conversionDraft from './draftConversion';
import media from './media';
import onboarding from './onboarding';
import rfm from './rfm';
import capturedata from './captureData';
import business from './business';
import visitor from './visitor';
import algorithm from './algorithm';
import openai from './openai';
import datasource from './datasource';

export default {
  namespaced: true,
  modules: {
    audience,
    journeys,
    integrations,
    whatsapp,
    media,
    onboarding,
    cases,
    conversions,
    conversionDraft,
    rfm,
    capturedata,
    business,
    visitor,
    algorithm,
    openai,
    datasource,
  },
};
