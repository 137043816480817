/* eslint-disable no-console */
import { io } from 'socket.io-client';

export default {
  install(app, { url, options = {} }) {
    const socket = io(url, {
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 10, // Max reconnection attempts
      reconnectionDelay: 1000, // Time between reconnection attempts (1 second)
      reconnectionDelayMax: 5000, // Max time between reconnections
      randomizationFactor: 0.5, // Randomize the reconnection time
      transports: ['websocket'], // Force WebSocket only
      autoConnect: true,
      withCredentials: true, // Send cookies/auth tokens with requests
      timeout: 20000, // Connection timeout (20 seconds)
      ...options,
    });

    socket.on('connect', () => {
      console.log(`Socket.IO connected: ${socket.id}`);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket.IO disconnected:', reason);

      if (reason === 'io server disconnect') {
        console.log('Server disconnected the client. No reconnection will be attempted.');
      } else if (reason === 'transport close' || reason === 'transport error') {
        console.log('Reconnecting due to network issues...');
      }
    });

    // Handle reconnection attempt event
    socket.on('reconnect_attempt', (attemptNumber) => {
      console.log(`Socket.IO reconnect attempt ${attemptNumber}`);
    });

    socket.on('connect_error', (error) => {
      console.log('Connection error:', error.message);

      if (error.message === 'TransportError') {
        console.log('Transport error occurred.');
      }
    });

    socket.on('error', (error) => {
      console.log('Socket error occurred:', error.message);
    });

    app.provide('$socket', socket);
  },
};
