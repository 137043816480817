/* eslint-disable no-return-assign, no-param-reassign */
import moment from 'moment';

import { tglyrPrice } from '@helpers/numbers/numbers.helper';

import { getAnalyticValue, calculateConversions } from '../main';

const getCaseAnalyticValue = ({ analytics, analyticValueName, caseItem, pluginId, resultValueName }) => {
  caseItem.caseId = caseItem._id;
  return getAnalyticValue({
    analytics,
    analyticValueName,
    channel: caseItem,
    channelIdName: 'caseId',
    childrenName: 'plugins',
    childId: pluginId,
    customKey: 'pluginId',
    resultValueName,
  });
};

const getCaseConversions = ({ analytics, filters, caseItem, pluginId }) => {
  caseItem.caseId = caseItem._id;
  return calculateConversions({
    analytics,
    analyticsValueName: 'conversions_per_plugin',
    channel: caseItem,
    channelIdName: 'caseId',
    childrenName: 'plugins',
    filters,
    conversionChild: 'cases',
    childId: pluginId,
    customKey: 'pluginId',
  });
};

const getCasePlugins = ({ analytics, caseItem }) => {
  const caseData = analytics.impressions_per_plugin.find((item) => item.caseId === caseItem._id);

  if (caseData) {
    return caseData.plugins;
  }

  return [];
};

const getTotalConversions = ({ analytics, filters }) => {
  const conversionsData = !filters.length
    ? analytics.conversions_per_plugin
    : analytics.conversions_per_plugin.filter((conversion) => filters.includes(conversion.conversionId));

  return conversionsData.reduce(
    (acc, item) => {
      acc.total += item.total;
      acc.value += item.value;

      return acc;
    },
    {
      total: 0,
      value: 0,
    },
  );
};

const getTotalConversionsChart = ({ analytics, filters }) => {
  const xasCategories = analytics.conversions_per_plugin.map((day) => moment(day.date).format('D MMM YYYY'));

  const currentConversions = !filters.length
    ? analytics.conversions_per_plugin
    : analytics.conversions_per_plugin.map((day) => ({
      ...day,
      data: day.data.filter((conversion) => filters.includes(conversion.conversionId)),
    }));

  return {
    xasCategories,
    series: [
      {
        name: 'Total conversions',
        data: currentConversions.map((day) => day.data.reduce((acc, item) => (acc += item.total), 0)),
      },
      // {
      //   name: 'Conversion value',
      //   data: currentConversions.map((day) => day.data.reduce((acc, item) => (acc += item.value), 0)),
      // },
    ],
  };
};

const getTotalConversionRate = ({ analytics, filters }) => {
  const conversions = !filters.length
    ? analytics.conversions_per_plugin.reduce((acc, item) => (acc += item.total), 0)
    : analytics.conversions_per_plugin
      .filter((conversion) => filters.includes(conversion.conversionId))
      .reduce((acc, item) => (acc += item.total), 0);
  const clicks = analytics.clickthroughs_per_plugin.reduce((acc, item) => (acc += item.total), 0);
  return clicks ? conversions / clicks : 0;
};

const getTotalConversionRateChart = ({ analytics, filters }) => {
  const clicks = analytics.clickthroughs_per_plugin;
  const conversions = !filters.length
    ? analytics.conversions_per_plugin
    : analytics.conversions_per_plugin.map((day) => ({
      ...day,
      data: day.data.filter((conversion) => filters.includes(conversion.conversionId)),
    }));

  return {
    xasCategories: analytics.conversions_per_plugin.map((day) => moment(day.date).format('D MMM YYYY')),
    series: [
      {
        name: 'Conversion rate',
        data: conversions.map((day, index) => {
          /* eslint-disable no-param-reassign */
          const dailyClicks = clicks[index].data.reduce((acc, item) => (acc += item.total), 0);
          const tmpConversions = day.data.reduce((acc, item) => (acc += item.total), 0);

          return dailyClicks ? tmpConversions / dailyClicks : 0;
        }),
      },
    ],
  };
};

const getTotalClickthroughRate = ({ analytics }) => {
  const impressions = analytics.impressions_per_plugin.reduce((acc, item) => (acc += item.total), 0);
  const clicks = analytics.clickthroughs_per_plugin.reduce((acc, item) => (acc += item.total), 0);

  return impressions ? clicks / impressions : 0;
};

const getTotalClickthroughRateChart = ({ analytics }) => {
  const clicks = analytics.clickthroughs_per_plugin;
  const impressions = analytics.impressions_per_plugin;

  return {
    xasCategories: analytics.conversions_per_plugin.map((day) => moment(day.date).format('D MMM YYYY')),
    series: [
      {
        name: 'Clickthrough rate',
        data: clicks.map((day, index) => {
          /* eslint-disable no-param-reassign */
          const dailyImpressions = impressions[index].data.reduce((acc, item) => (acc += item.total), 0);
          const tmpClicks = day.data.reduce((acc, item) => (acc += item.total), 0);
          /* eslint-enable no-param-reassign */
          return dailyImpressions ? tmpClicks / dailyImpressions : 0;
        }),
      },
    ],
  };
};

/**
 *
 * @param {*} rate
 * @param {*} cases
 * @returns
 */
export const isBestVariation = (rate, cases) => {
  const conversionRates = cases.map((obj) => obj.conversionRate);
  const hightestConversionRate = Math.max(...conversionRates);

  return rate ? rate >= hightestConversionRate && conversionRates.filter((obj) => obj === rate).length === 1 : false;
};

const defaultWidgets = [
  {
    key: 'interacted',
    name: 'Taglayer conversions',
    value: null,
    chart: null,
    format: 'number',
    colors: ['#4389fc', '#311704'],
  },
  {
    key: 'conversion_rate',
    name: 'Conversion rate',
    value: null,
    chart: null,
    format: 'percent',
    colors: ['#4389fc'],
  },
  {
    key: 'clickthrough_rate',
    name: 'Clickthrough rate',
    value: null,
    chart: null,
    format: 'percent',
    colors: ['#4389fc'],
  },
];

export default {
  calculateTable({ cases, analytics, filters }) {
    return cases
      .reduce((acc, caseItem) => {
        const caseImpressions = getCaseAnalyticValue({
          analytics,
          analyticValueName: 'impressions_per_plugin',
          caseItem,
          resultValueName: 'total',
        });

        const caseClicks = getCaseAnalyticValue({
          analytics,
          analyticValueName: 'clickthroughs_per_plugin',
          caseItem,
          resultValueName: 'total',
        });

        const caseConversions = getCaseConversions({
          analytics,
          caseItem,
          filters,
        });

        const casePlugins = getCasePlugins({
          analytics,
          caseItem,
          filters,
        });

        if (!(caseImpressions && caseImpressions !== 0)) return acc;

        return [
          ...acc,
          {
            name: caseItem.name,
            impressions: caseImpressions,
            clicks: caseClicks,
            conversions: caseConversions.total,
            conversionsValue: caseConversions.value,
            ctr: caseClicks / caseImpressions || 0,
            conversionRate: caseConversions.total / caseClicks || 0,
            plugins: casePlugins.map((plugin) => {
              const pluginImpressions = getCaseAnalyticValue({
                analytics,
                analyticValueName: 'impressions_per_plugin',
                caseItem,
                pluginId: plugin.pluginId,
                resultValueName: 'count',
              });

              const pluginClicks = getCaseAnalyticValue({
                analytics,
                analyticValueName: 'clickthroughs_per_plugin',
                caseItem,
                pluginId: plugin.pluginId,
                resultValueName: 'count',
              });

              const pluginConversions = getCaseConversions({
                analytics,
                caseItem,
                pluginId: plugin.pluginId,
                filters,
              });
              return {
                name: plugin.pluginKey,
                impressions: pluginImpressions,
                clicks: pluginClicks,
                conversions: pluginConversions.total,
                conversionsValue: pluginConversions.value,
                ctr: pluginClicks / pluginImpressions || 0,
                conversionRate: pluginConversions.total / pluginClicks || 0,
              };
            }),
          },
        ];
      }, [])
      .sort((a, b) => b.impressions - a.impressions);
  },
  calculateWidgets({ analytics, filters, conversions }) {
    const { value: interactedCurValue, total: interactedCurTotal } = getTotalConversions({
      analytics: analytics.total,
      filters,
      conversions,
    });

    const data = {
      interacted: {
        value: interactedCurTotal,
        tags: interactedCurValue > 0 ? [
          {
            type: 'success',
            text: tglyrPrice(interactedCurValue),
          },
        ] : [],
        chart: getTotalConversionsChart({
          analytics: analytics.daily,
          filters,
        }),
      },
      conversion_rate: {
        value: getTotalConversionRate({
          analytics: analytics.total,
          filters,
        }),
        chart: getTotalConversionRateChart({
          analytics: analytics.daily,
          filters,
        }),
      },
      clickthrough_rate: {
        value: getTotalClickthroughRate({
          analytics: analytics.total,
          filters,
        }),
        chart: getTotalClickthroughRateChart({
          analytics: analytics.daily,
          filters,
        }),
      },
    };

    return defaultWidgets.map((widget) => {
      const temp = { ...widget };
      temp.chart = data[widget.key].chart;
      temp.value = data[widget.key].value;
      return temp;
    });
  },
};
