const _state = {
  templates: [],
  loading: false,
};

const getters = {
  list: (state) => state.templates,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);

    const templates = await this.$api.website.templates.fetchTemplates();

    commit('SET_TEMPLATES', templates);

    commit('SET_LOADING', false);

    return templates;
  },
  async update({ dispatch }, payload) {
    const template = await this.$api.website.templates.updateTemplate(payload);

    await dispatch('fetch');

    return template;
  },
  async create({ dispatch }, payload) {
    const template = await this.$api.website.templates.createTemplate(payload);

    await dispatch('fetch');

    return template;
  },
  async remove({ dispatch }, id) {
    const templates = await this.$api.website.templates.removeTemplate(id);

    await dispatch('fetch');

    return templates;
  },
  async getTemplateById(_, id) {
    return this.$api.website.templates.getTemplateById(id);
  },
  async updateScreenshot({ dispatch }, id) {
    const template = await this.$api.website.templates.updateScreenshot(id);

    await dispatch('fetch');

    return template;
  },
  async testEmailTemplate(_, data) {
    const template = await this.$api.website.templates.testEmailTemplate(data);
    return template;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
