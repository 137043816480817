const _state = {
  list: [],
  types: [],
  authenticatedSenders: [],
  mailchimpAudiences: [],
  bigqueryTables: [],
};

const getters = {
  list: (state) => state.list,
  hasIntegration: (state) => (type) => state.list.some((intgr) => intgr.type === type),
  formatted: (state) => state.types
    .map((intgr) => {
      const found = state.list.find((integration) => integration.type === intgr.id);
      return {
        ...intgr,
        enabled: !!found,
        ...(found ? { config: found.config, integrationId: found._id, label: found.label } : {}),
      };
    })
    .sort((a) => (a.enabled ? -1 : 1)),
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LIST(state, integrations) {
    state.list = integrations;
  },
  SET_TYPES(state, integrations) {
    state.types = integrations;
  },
  SET_AUTHENTICATED_SENDERS(state, authenticatedSenders) {
    state.authenticatedSenders = authenticatedSenders;
  },
  SET_MAILCHIMP_AUDIENCES(state, audiences) {
    state.mailchimpAudiences = audiences;
  },
  SET_BIGQUERY_TABLES(state, tables) {
    state.bigqueryTables = tables;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    const integration = await this.$api.website.integrations.fetchIntegration();
    commit('SET_LIST', integration);

    return integration;
  },
  async fetchTypes({ commit }) {
    const integrationTypes = await this.$api.website.integrations.fetchIntegrationTypes();
    commit('SET_TYPES', integrationTypes);

    return integrationTypes;
  },
  async create({ dispatch }, data) {
    const integration = await this.$api.website.integrations.createIntegration(data);
    await dispatch('fetch');

    return integration;
  },
  async delete({ dispatch }, data) {
    const integration = await this.$api.website.integrations.deleteIntegration(data);
    await dispatch('fetch');

    return integration;
  },
  async checkPixel(_, data) {
    const pixels = await this.$api.website.integrations.checkPixel(data);

    return pixels;
  },
  async shopifyToken({ dispatch }) {
    const token = await this.$api.website.integrations.fetchShopifyStateToken();
    await dispatch('fetch');

    return token;
  },
  async connectShopify({ dispatch }, data) {
    const shopifyIntegration = await this.$api.website.integrations.connectShopify(data);
    await dispatch('fetch');

    return shopifyIntegration;
  },
  async connectHubspot({ dispatch }, data) {
    const hubspotIntegration = await this.$api.website.integrations.connectHubspot(data);
    await dispatch('fetch');

    return hubspotIntegration;
  },
  async fetchAuthenticatedSenders({ commit }) {
    const authenticatedSenders = await this.$api.website.integrations.fetchAuthenticatedSenders();
    commit('SET_AUTHENTICATED_SENDERS', authenticatedSenders);

    return authenticatedSenders;
  },
  async fetchMailchimpAudiences({ commit }, payload) {
    const mailchimpAudiences = await this.$api.website.integrations.fetchMailchimpAudiences(payload);
    commit('SET_MAILCHIMP_AUDIENCES', mailchimpAudiences);

    return mailchimpAudiences;
  },
  async fetchBigQueryTables({ commit }, payload) {
    const bigQueryTables = await this.$api.website.integrations.fetchBigQueryTables(payload);
    commit('SET_BIGQUERY_TABLES', bigQueryTables);

    return bigQueryTables;
  },
  async fetchBigQueryFields() {
    return this.$api.website.integrations.fetchBigQueryTableFields();
  },
  async createAuthenticatedSenders({ dispatch }) {
    const authenticatedSenders = await this.$api.website.integrations.createAuthenticatedSenders();
    await dispatch('fetchAuthenticatedSenders');
    return authenticatedSenders;
  },
  async validateAuthenticatedSender({ dispatch }, sengridDomainId) {
    const validationResult = await this.$api.website.integrations.validateAuthenticatedSender(sengridDomainId);
    await dispatch('fetchAuthenticatedSenders');
    return validationResult;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
