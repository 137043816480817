import axios from '@services/api/axios';

export const login = async (data = {}) => {
  const response = await axios.post('/auth/login', data);

  return response.data;
};

export const logout = async () => {
  const response = await axios.post('/auth/logout', {});

  return response.data;
};

export const register = async (data = {}) => {
  const response = await axios.post('/auth/register', data);

  return response.data;
};

export const fetchAuthenticatedDomain = async () => {
  const response = await axios.get('/auth/domain');

  return response.data;
};

export const twoFactor = async (data = {}) => {
  const response = await axios.post('/auth/two-factor/login', data);

  return response.data;
};
