export default [
  {
    path: '/journeys',
    children: [
      {
        path: '',
        redirect: '/journeys/overview',
      },
      {
        path: 'overview',
        component: () => import('./overview/journeys-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'builder/:journey',
        component: () => import('./builder/builder.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
