const _state = {
  loading: false,
  segments: [],
};

const getters = {
  loading: (state) => state.loading,
  segments: (state) => state.segments,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_SEGMENTS: (state, segments) => {
    state.segments = segments;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_LOADING', true);

    const rfms = await this.$api.website.rfm.fetchRfms();
    commit('SET_SEGMENTS', rfms);

    dispatch('analytics/formatRfms', null, { root: true });

    commit('SET_LOADING', false);

    return rfms;
  },
  async fetchWithoutFormat({ commit }) {
    commit('SET_LOADING', true);

    const rfms = await this.$api.website.rfm.fetchRfms();
    commit('SET_SEGMENTS', rfms);
    commit('SET_LOADING', false);
  },
  async create({ dispatch }, config) {
    const rfm = await this.$api.website.rfm.createRfm(config);

    // refetch all segment data
    await dispatch('fetch');

    return rfm;
  },
  async update({ dispatch }, { id, data }) {
    const rfm = await this.$api.website.rfm.updateRfm(id, data);

    // refetch all segment data
    await dispatch('fetch');

    return rfm;
  },
  async remove({ dispatch }, id) {
    const rfm = await this.$api.website.rfm.deleteRfm(id);

    // refetch all segment data
    await dispatch('fetch');

    return rfm;
  },
  async generate({ dispatch }, conversions) {
    await this.$api.website.rfm.generateRfm(conversions);
    // refetch all segment data
    await dispatch('fetch');
  },
  async fetchVisitorRfmsegment(_, { domain, visitor }) {
    return this.$api.website.visitor.fetchRfmSegment({ domain, visitor });
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  mutations,
  actions,
};
