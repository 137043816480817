const _state = {};

/* eslint-disable no-param-reassign */
const getters = {
  user: (state, _getters, rootState) => rootState.user.data,
  company: (state, _getters, rootState) => rootState.company.data,
  domain: (state, _getters, rootState) => rootState.domain.data,
};
/* eslint-enable no-param-reassign */

const mutations = {};

const actions = {
  async register({ dispatch }, data) {
    const response = await this.$api.auth.register(data);

    await dispatch('fetch');

    return response;
  },
  async login({ dispatch }, data) {
    const user = await this.$api.auth.login(data);

    if (!user.two_factor_enabled) await dispatch('fetch');

    return user;
  },
  async logout({ commit }) {
    const user = await this.$api.auth.logout();

    await commit('user/SET_DATA', null, { root: true });

    return user;
  },
  async fetch({ dispatch }) {
    const user = await dispatch('user/fetch', null, { root: true });
    const company = await dispatch('company/fetch', null, { root: true });
    const domain = await dispatch('domain/fetch', null, { root: true });

    return {
      user,
      company,
      domain,
    };
  },
  async twoFactor({ dispatch }, data) {
    const response = await this.$api.auth.twoFactor(data);

    await dispatch('fetch');

    return response.data;
  },
  async fetchAuthenticatedDomain() {
    const domain = await this.$api.auth.fetchAuthenticatedDomain();

    return domain;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
