// Update this when billing gets worked on
const _state = () => ({
  pricingTiers: null,
  estimatedPageviews: null,
  paymentMethods: [],
  cards: [],
  loadingCards: false,
  invoices: [],
  currentStep: 0,
});

const getters = {};

const actions = {
  async fetchPricingTiers({ commit }) {
    const pricingTiers = await this.$api.billing.getPricing();
    commit('SET_PRICING_TIERS', pricingTiers);
    return pricingTiers;
  },
  async fetchEstimatedPageviews({ commit }) {
    const estimatedPageviews = await this.$api.billing.getEstimatedPageviews();
    commit('SET_ESTIMATED_PAGEVIEWS', estimatedPageviews);
    return estimatedPageviews;
  },
  async getPaymentMethods({ commit }) {
    const paymentMethods = await this.$api.billing.getPaymentMethods();
    commit('SET_PAYMENT_METHODS', paymentMethods);
    return paymentMethods;
  },
  async getPaymentCards({ commit }) {
    commit('SET_LOADING_CARDS', true);

    const cards = await this.$api.billing.getPaymentCards();
    commit('SET_CARDS', cards);

    commit('SET_LOADING_CARDS', false);
    return cards;
  },
  async updatePaymentCard({ dispatch }, mandateId) {
    await this.$api.billing.updateCard({ mandateId });
    const cards = await dispatch('getPaymentCards');
    return cards;
  },
  async createPaymentCard(_, method) {
    return this.$api.billing.createCard({ method });
  },
  async deleteCard({ dispatch }, mandateId) {
    await this.$api.billing.deleteCard(mandateId);
    const cards = await dispatch('getPaymentCards');
    return cards;
  },
  async getInvoices({ commit }) {
    const invoices = await this.$api.billing.getInvoices();
    commit('SET_INVOICES', invoices);
    return invoices;
  },
  async setupPayment(_, paymentDetails) {
    return this.$api.billing.setupPayment(paymentDetails);
  },
  async updatePricing(_, pricingDetails) {
    return this.$api.billing.updatePricing(pricingDetails);
  },
  async updateBillingInformation({ dispatch }, billingInformation) {
    const response = await this.$api.billing.updateBillingInformation(billingInformation);
    await dispatch('domain/fetch', null, { root: true });
    return response;
  },
  changeStep: ({ commit }, amount) => {
    commit('UPDATE_STEP', amount);
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  SET_PRICING_TIERS(state, pricingTiers) {
    state.pricingTiers = pricingTiers;
  },
  SET_ESTIMATED_PAGEVIEWS(state, estimatedPageviews) {
    state.estimatedPageviews = estimatedPageviews;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_CARDS(state, cards) {
    state.cards = cards;
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
  SET_STEP(state, step) {
    state.currentStep = step;
  },
  UPDATE_STEP: (state, step) => {
    state.currentStep += step;
  },
  SET_LOADING_CARDS: (state, loading) => {
    state.loadingCards = loading;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
