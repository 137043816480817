const _state = {
  list: [],
  loading: false,
  allStages: [],
  stageOptions: [],
  stage: {},
  creatingStage: false,
  journeyFullyLoaded: false,
};

const getters = {
  creatingStage: (state) => state.creatingStage,
  stageOptions: (state) => state.stageOptions,
  tags: (state) => [...new Set(state.list.map((journeyItem) => journeyItem.tags).flat())],
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_LIST(state, journeys) {
    state.list = journeys;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_STAGES(state, stages) {
    state.allStages = stages;
  },
  setStageOptions(state, options) {
    state.stageOptions = options;
  },
  SET_CREATING_STAGE: (state, creatingStage) => {
    state.creatingStage = creatingStage;
  },
  setStage: (state, stage) => {
    state.stage = stage;
  },
  setJourneyFullyLoaded: (state, fullyLoaded) => {
    state.journeyFullyLoaded = fullyLoaded;
  },
};
/* eslint-enable no-param-reassign */

/* eslint-disable no-useless-catch */
const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);

    const journeys = await this.$api.website.journeys.fetchJourneys();

    commit('SET_LIST', journeys);
    commit('SET_LOADING', false);

    return journeys;
  },
  async fetchWithoutLoader({ commit }) {
    const journeys = await this.$api.website.journeys.fetchJourneys();

    commit('SET_LIST', journeys);

    return journeys;
  },
  async fetchStages({ commit }) {
    const stages = await this.$api.website.journeys.fetchAllStages();

    commit('SET_STAGES', stages);

    return stages;
  },
  async fetchStageOptions({ commit }) {
    const journeys = await this.$api.website.journeys.fetchStageOptions();
    commit('setStageOptions', journeys);

    return journeys;
  },
  async setJourneyFullyLoaded({ commit }, payload) {
    commit('setJourneyFullyLoaded', payload);
  },
  async getStageByID({ commit }, id) {
    const stage = await this.$api.website.journeys.getStageByID(id);
    commit('setStage', stage);
    return stage;
  },
  async create({ dispatch }, data) {
    let journey;
    try {
      journey = await this.$api.website.journeys.createJourney(data);
      await dispatch('fetch');
      return journey;
    } catch (e) {
      throw e;
    }
  },
  async delete({ dispatch }, data) {
    let journey;
    try {
      journey = await this.$api.website.journeys.deleteJourney(data);
      await dispatch('fetch');
      return journey;
    } catch (e) {
      throw e;
    }
  },
  async update({ dispatch }, data) {
    let journey;
    try {
      journey = await this.$api.website.journeys.updateJourney(data);
      await dispatch('fetch');
      return journey;
    } catch (e) {
      throw e;
    }
  },
  async createStage({ dispatch }, payload) {
    let stage;
    try {
      stage = await this.$api.website.journeys.createJourneyStage(payload);

      await dispatch('fetch');
      return stage;
    } catch (e) {
      throw e;
    }
  },
  async updateStage({ dispatch }, { id, payload }) {
    let journey;
    try {
      journey = await this.$api.website.journeys.updateJourneyStage(id, payload);
      await dispatch('fetch');
      return journey;
    } catch (e) {
      throw e;
    }
  },
  async deleteStage({ dispatch }, { id, variation }) {
    let journey;
    try {
      journey = await this.$api.website.journeys.deleteJourneyStage(id, variation);
      await dispatch('fetch');

      return journey;
    } catch (e) {
      throw e;
    }
  },
  async testStage(_, payload) {
    let test;
    try {
      test = await this.$api.website.journeys.testStage(payload);
      return test;
    } catch (error) {
      throw error;
    }
  },
  async publishJourney({ dispatch }, journeyId) {
    let response;
    try {
      response = await this.$api.website.journeys.publishJourney(journeyId);
      await dispatch('fetch');
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
