const _state = {
  algorithms: [],
};

const getters = {
  all: (state) => state.algorithms,
  nonProductBased: (state) => state.algorithms.filter((algorithm) => !algorithm.productBased),
};

const mutations = {
  SET_ALGORITHMS_LIST(state, algorithms) {
    // eslint-disable-next-line no-param-reassign
    state.algorithms = algorithms;
  },
};

const actions = {
  async fetch({ commit }) {
    const algorithms = await this.$api.algorithms.fetch();
    commit('SET_ALGORITHMS_LIST', algorithms);
    return algorithms;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
