import axios from '@services/api/axios';

export const fetchIntegrationTypes = async () => {
  const response = await axios.get('/integration/types');
  return response.data;
};

export const fetchIntegration = async () => {
  const response = await axios.get('/integration');
  return response.data;
};

export const createIntegration = async (payload) => {
  const response = await axios.post('/integration', payload);

  return response.data;
};

export const deleteIntegration = async (payload) => {
  const response = await axios.delete(`/integration/${payload}`);
  return response.data;
};

export const checkPixel = async (payload) => {
  const response = await axios.post('/integration/check-pixels', payload);

  return response.data;
};

export const fetchShopifyStateToken = async () => {
  const response = await axios.get('/integration/shopify/token');
  return response.data.token;
};

export const connectShopify = async (payload) => {
  const response = await axios.post('/integration/shopify', payload);
  return response.data;
};

export const connectHubspot = async (payload) => {
  const response = await axios.post('/integration/hubspot', payload);
  return response.data;
};

export const fetchAuthenticatedSenders = async () => {
  const response = await axios.get('/integration/sendgrid/authenticated');
  return response.data;
};

export const createAuthenticatedSenders = async () => {
  const response = await axios.post('/integration/sendgrid/domain/auth');
  return response.data;
};

export const validateAuthenticatedSender = async (sengridDomainId) => {
  const response = await axios.post(`/integration/sendgrid/domain/validate/${sengridDomainId}`);
  return response.data;
};

export const fetchMailchimpAudiences = async (payload) => {
  const response = await axios.post('/integration/mailchimp', payload);
  return response.data;
};

export const fetchBigQueryTables = async (payload) => {
  const response = await axios.post('/integration/bigquery', payload);
  return response.data;
};

export const fetchBigQueryTableFields = async () => {
  const response = await axios.get('/integration/bigquery/fields');
  return response.data;
};
