<template>
  <div class="fixed inset-0 bg-gray-50">
    <div class="fixed top-[-60%] border-b left-0 right-0 h-full w-full bg-blue-50 z-0 transform skew-y-12" />

    <div class="flex flex-col items-center justify-center h-full">
      <slot />
    </div>

    <div class="absolute text-gray-500 bottom-0 left-0 m-4">
      &copy; {{ currentYear }} Taglayer &bullet;
      <a
        href="https://www.taglayer.com/legal/privacy"
        class="text-gray-400"
        target="_blank"
      >
        Privacy
      </a>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  setup() {
    const currentYear = computed(() => {
      const today = new Date();

      return today.getFullYear();
    });

    return {
      currentYear,
    };
  },
};
</script>
