import moment from 'moment';
import { getAnalyticValue, calculateConversions } from '../main';

const getNotificationAnalyticValue = ({ analytics, analyticValueName, journey, childId }) =>
  getAnalyticValue({
    analytics,
    analyticValueName,
    channel: journey,
    channelIdName: 'journeyId',
    childrenName: 'stages',
    childId,
    customKey: 'stageId',
    resultValueName: 'total',
  });

const getNotificationConversions = ({ analytics, journey, filters, childId }) =>
  calculateConversions({
    analytics,
    analyticsValueName: 'notification_conversions',
    channel: journey,
    channelIdName: 'journeyId',
    childrenName: 'stages',
    filters,
    conversionChild: 'journeys',
    childId,
    customKey: 'stageId',
  });

export default {
  calculateTable({ analytics, filters, journeys, stagesMap }) {
    const journeyAnalyticMap = analytics.notification_impressions.map((entry) => ({
      journeyId: entry.journeyId,
      stages: entry.stages,
    }));
    return journeyAnalyticMap.flatMap((journey) => {
      const notificationJourney = journeys.find((entry) => entry._id === journey.journeyId);
      if (!notificationJourney) return null;

      const notificationsImpressions = getNotificationAnalyticValue({
        analytics,
        analyticValueName: 'notification_impressions',
        journey,
      });

      const notificationClicks = getNotificationAnalyticValue({
        analytics,
        analyticValueName: 'notification_clicks',
        journey,
      });

      const notificationConversions = getNotificationConversions({
        analytics,
        journey,
        filters,
      });

      return [
        {
          name: notificationJourney.name,
          journeyId: notificationJourney._id,
          impressions: notificationsImpressions,
          clicks: notificationClicks,
          clickRate: notificationClicks / notificationsImpressions || 0,
          conversions: notificationConversions.total,
          conversionRate: notificationConversions.total / notificationClicks || 0,
          conversionValue: notificationConversions.value,
          stages: journey.stages.map((stage) => {
            const stageTree = stagesMap.find((entry) => entry.stageId === stage.stageId)?.tree;

            const stageImpression = getNotificationAnalyticValue({
              analytics,
              analyticValueName: 'notification_impressions',
              journey,
              childId: stage.stageId,
            });

            const stageClicks = getNotificationAnalyticValue({
              analytics,
              analyticValueName: 'notification_clicks',
              journey,
              childId: stage.stageId,
            });

            const stageConversions = getNotificationConversions({
              analytics,
              journey,
              childId: stage.stageId,
              filters,
            });

            return {
              tree: stageTree,
              impressions: stageImpression,
              clicks: stageClicks,
              clickRate: stageClicks / stageImpression || 0,
              conversions: stageConversions.total,
              conversionRate: stageConversions.total / stageClicks || 0,
              conversionValue: stageConversions.value,
            };
          }),
        },
      ];
    });
  },
  calculateWidgets({ analytics }) {
    const createChart = ({ name, data }) => ({
      xasCategories: data.map((day) => moment(day.date).format('D MMM YYYY')),
      series: [
        {
          name,
          data: data.reduce((prev, next) => {
            const acc = 0;
            // eslint-disable-next-line no-param-reassign, no-return-assign
            const total = next.data.reduce((preceding, upcoming) => (preceding += upcoming.total), acc);
            prev.push(total);
            return prev;
          }, []),
        },
      ],
    });
    // eslint-disable-next-line no-param-reassign, no-return-assign
    const getTotalValue = (data) => data.reduce((prev, next) => (prev += next.data.reduce((p, n) => (p += n.total), 0)), 0);

    return [
      {
        key: 'notifications sent',
        name: 'Notifications sent',
        value: getTotalValue(analytics.notification_impressions),
        chart: createChart({ name: 'Notifications sent', data: analytics.notification_impressions }),
        colors: ['#ef4444'],
        format: 'number',
      },
      {
        key: 'clicks',
        name: 'Clicks',
        value: getTotalValue(analytics.notification_clicks),
        chart: createChart({ name: 'Clicks', data: analytics.notification_clicks }),
        colors: ['#ef4444'],
        format: 'number',
      },
    ];
  },
};
