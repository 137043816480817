import templates from './templates';
import builder from './builder';

export default {
  namespaced: true,
  modules: {
    templates,
    builder,
  },
};
