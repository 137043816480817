const _state = {};

const getters = {};

const mutations = {};

const actions = {
  async openAITextSuggestions(_, payload) {
    const response = await this.$api.website.openai.openAITextSuggestions(payload);

    return response;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
