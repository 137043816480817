import axios from '@services/api/axios';

export const fetch = async () => {
  const response = await axios.get('/user');

  return response.data;
};

export const fetchCompanyTeam = async () => {
  const response = await axios.get('/user/team');

  return response.data;
};

export const update = async (payload) => {
  const response = await axios.put('/user', payload);

  return response.data;
};

export const remove = async () => {
  const response = await axios.delete('/user');

  return response.data;
};

export const updatePassword = async (payload) => {
  const response = await axios.put('/user/password', payload);

  return response.data;
};

export const invite = async (payload) => {
  const response = await axios.post('/user/invite', payload);

  return response.data;
};
export const completeInvite = async (payload) => {
  const response = await axios.post('/user/invite/complete', payload);

  return response.data;
};
export const sendPasswordReset = async (payload) => {
  const response = await axios.post('/user/reset', payload);

  return response.data;
};

export const savePasswordReset = async (payload) => {
  const response = await axios.post('/user/reset/save', payload);

  return response.data;
};

export const verifyEmail = async ({ token }) => {
  const response = await axios.put(`/user/verify/${token}`);

  return response.data;
};

export const fetchTwoFactorSetup = async () => {
  const response = await axios.get('/user/two-factor');

  return response.data;
};

export const enableTwoFactor = async (payload) => {
  const response = await axios.post('/user/two-factor/enable', payload);

  return response.data;
};

export const disableTwoFactor = async () => {
  const response = await axios.post('/user/two-factor/disable');

  return response.data;
};

export const addEmailReport = async (payload) => {
  const response = await axios.post('/user/emailreport', payload);

  return response.data;
};

export const deleteEmailReport = async (payload) => {
  const response = await axios.delete(`/user/emailReport/${payload.id}`);

  return response.data;
};

export const resendInvitation = async (payload) => {
  const response = await axios.post(`/user/invite/resend/${payload}`);

  return response.data;
};

export const resendVerification = async (payload) => {
  const response = await axios.post('/user/resend-verification', payload);

  return response.data;
};

export const updateUserRole = async ({ id, role }) => {
  const response = await axios.put(`/user/team/${id}`, { role });

  return response.data;
};

export const deleteUserFromTeam = async (id) => {
  const response = await axios.delete(`/user/team/${id}`);

  return response.data;
};
