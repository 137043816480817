<template>
  <div class="h-screen w-screen bg-gray-50 flex">
    <navigation-main v-if="$store.getters['auth/user']" />

    <div
      v-if="!switchingDomain"
      class="h-full w-full backgroundSwirl"
    >
      <slot />
    </div>
    <div
      v-else
      class="w-full flex items-center justify-center"
    >
      <tglyr-loading :size="48" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import NavigationMain from '@components/navigation/navigation-main.vue';

const store = useStore();
const switchingDomain = computed(() => store.state.domain.switching);
</script>
