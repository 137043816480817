/**
 * Converts a float number to a Taglayer percentage format(xx,xx%)
 * used in tables (Performance / cases)
 * @param floatInput
 * @returns string
 */
const tglyrPercent = (floatInput) => `${(floatInput * 100 || 0).toFixed(2).toString().replace('.', ',')}%`;

/**
 * Converts a number to a formatted readable string (e.g. 1000 => '1.000')
 * @param value
 * @returns string
 */
const tglyrNumber = (value) => (new Intl.NumberFormat('nl-BE').format(value ?? '0'));

const tglyrPrice = (value) => (new Intl.NumberFormat('nl-BE', {
  style: 'currency',
  currency: 'EUR',
}).format(value ?? '0'));

export { tglyrPercent, tglyrNumber, tglyrPrice };
