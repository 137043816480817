import { createStore, createLogger } from 'vuex';

import apiPlugin from './plugins/api';

import AuthModule from './modules/auth';
import BillingModule from './modules/billing';
import UserModule from './modules/user';
import DomainModule from './modules/domain';
import CompanyModule from './modules/company';
import ScraperModule from './modules/scraper';
import WebsiteModule from './modules/website';
import EmailModule from './modules/email';
import AnalyticsModule from './modules/analytics';
import InterestsModule from './modules/interests';

const viteLogger = import.meta.env.VITE_LOGGER;

export default createStore({
  modules: {
    auth: AuthModule,
    billing: BillingModule,
    user: UserModule,
    domain: DomainModule,
    company: CompanyModule,
    scraper: ScraperModule,
    website: WebsiteModule,
    email: EmailModule,
    analytics: AnalyticsModule,
    interests: InterestsModule,
  },
  strict: false,
  plugins: viteLogger === true ? [createLogger(), apiPlugin] : [apiPlugin],
  devtools: process.env.NODE_ENV !== 'production',
});
