import axios from '@services/api/axios';

export const fetchCaptureData = async () => {
  const response = await axios.get('/website/capturedata');

  return response.data;
};

export const fetchDomainCaptureData = async (lastVisit = null) => {
  const resp = await axios.get(`/website/capturedata/domain?lastVisit=${lastVisit}`);
  return resp.data;
};

export const createCaptureData = async (payload) => {
  const response = await axios.post('/website/capturedata', payload);
  return response.data;
};
