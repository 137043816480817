import axios from '@services/api/axios';

export const fetchRecommendations = async ({ domain, collection, visitor, algorithm = 'visitor_item' }) => {
  const response = await axios.get(`/website/visitor/recommendations/${domain}/${algorithm}/${collection}/${visitor}`);

  return response.data;
};

export const fetchRfmSegment = async ({ domain, visitor }) => {
  const response = await axios.get(`/website/visitor/rfm/${domain}/${visitor}`);

  return response.data;
};

export const fetchVisitor = async (id) => {
  const response = await axios.get(`/website/visitor/${id}`);
  return response.data;
};

export const fetchVisitorData = async (id, { failed }) => {
  const query = new URLSearchParams({ failed });
  const response = await axios.get(`/website/visitor/activity/${id}?${query}`);
  return response.data;
};

export const fetchVisitorProfile = async (id, { failed }) => {
  const query = new URLSearchParams({ failed });
  const response = await axios.get(`/website/visitor/profile/${id}?${query}`);
  return response.data;
};

export const unsubscribeVisitor = async (payload) => {
  await axios.post('/email/track/unsubscribe/visitor', payload);
};

export const fetchVisitorAudience = async (visitor) => {
  const response = await axios.get(`/website/visitor/audience/${visitor}`);
  return response.data;
};
