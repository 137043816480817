import axios from '@services/api/axios';

export const fetchDataSources = async () => {
  const response = await axios.get('/website/data-source');
  return response.data;
};

export const fetchContactsAmounts = async () => {
  const response = await axios.get('/website/data-source/contact/amount');
  return response.data;
};

export const fetchSourceById = async (id) => {
  const response = await axios.get(`/website/data-source/${id}/details`);
  return response.data;
};

export const createDataSource = async (payload) => {
  const response = await axios.post('/website/data-source', payload);

  return response.data;
};

export const fetchDataSourceTypes = async () => {
  const response = await axios.get('/website/data-source/config');
  return response.data;
};

export const syncDataSource = async (sourceId) => {
  const response = await axios.post('/website/data-source/sync', {
    sourceId,
  });
  return response.data;
};

export const fetchSourceContacts = async (id, { page }) => {
  const response = await axios.get(`/website/data-source/${id}?page=${page}`);
  return response.data;
};

export const fetchSourceContactById = async (id, { failed }) => {
  const query = new URLSearchParams({ failed });
  const response = await axios.get(`/website/data-source/contact/${id}?${query}`);
  return response.data;
};

export const sendContactEmail = async (sourceId, payload) => {
  const response = await axios.post(`/website/data-source/${sourceId}/send/email`, payload);

  return response.data;
};

export const unsubscribeContact = async (contactId) => {
  await axios.post('/email/track/unsubscribe/contact', { contactId });
};

export const unsubscribeContactByEmail = async ({ email, domain }) => {
  await axios.post('/email/track/unsubscribe/contact/email', { email, domain });
};

export const removeDataSource = async (id) => {
  const response = await axios.delete(`/website/data-source/${id}`);

  return response.data;
};

export const updateDataSource = async (id, payload) => {
  const response = await axios.put(`/website/data-source/${id}`, payload);
  return response.data;
};
