import { calculateConversions } from '../main';

export default {
  calculateTable({ segments, analytics, filters }) {
    return segments.map((segment) => {
      const visitors = analytics.visitors_per_segment.find((seg) => seg.segmentId === segment._id);
      const visits = analytics.visits_per_segment.find((seg) => seg.segmentId === segment._id);

      const rfmConversions = calculateConversions({
        analytics,
        analyticsValueName: 'conversions_per_segment',
        channel: {
          ...segment,
          segmentId: segment._id,
        },
        channelIdName: 'segmentId',
        filters,
        conversionChild: 'rfms',
      });
      return {
        id: segment._id,
        name: segment.name,
        conversions: rfmConversions.total,
        conversionRate: visitors && visitors.total ? visitors.total / rfmConversions.total : null,
        conversionValue: rfmConversions.value,
        visits: visits?.total || 0,
        visitors: visitors?.total || 0,
        active: segment.active,
      };
    });
  },
  fillWidget(segments) {
    return {
      key: 'visitors',
      name: 'Total visitors',
      chart: {
        xasCategories: segments.map((rfm) => rfm.name),
        series: [{ name: 'Visitors', data: segments.map((rfm) => rfm.visitors) }],
      },
      value: segments.reduce((acc, next) => acc + next.visitors, 0),
      format: 'number',
      grid: true,
      type: 'bar',
      colors: [
        '#4389fc',
        '#ffaa6e',
        '#62bdb0',
        '#9780fd',
        '#f96d7a',
        '#b450fc',
        '#ffe866',
        '#dd5394',
        '#998a8a',
        '#D2B48C',
        '#47ffff',
        '#70ff70',
        '#636363',
        '#b7fc56',
        '#f7754d',
      ],
    };
  },
};
