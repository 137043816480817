import axios from '@services/api/axios';

export const generateImage = async (payload) => {
  const response = await axios.post('/openai/generate', payload);
  return response.data;
};

export const generateRewrittenOrTranslate = async (payload) => {
  const body = {
    type: payload.type,
    language: payload.language,
    prompt: payload.prompt,
    model: payload.model,
  };
  const response = await axios.post('/openai/chat/text/request', body);
  return response.data;
};

export const generateResponeOnPrompt = async (payload) => {
  const response = await axios.post('/openai/chat/text/completions', payload);
  return response.data;
};

export const openAITextSuggestions = async (payload) => {
  const response = await axios.post('/openai/chat/text/request', {
    prompt: payload.prompt,
    type: payload.type,
  });

  return response.data;
};
