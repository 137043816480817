const _state = {
  userOnboarding: [],
  modalOpen: false,
};

const getters = {};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_USER_ONBOARDING(state, onboarding) {
    state.userOnboarding = onboarding;
  },
  SET_MODAL_STATE(state, open) {
    state.modalOpen = open;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async setOpenState({ commit }, payload) {
    commit('SET_MODAL_STATE', payload);
  },
  async getUserOnboarding({ commit }) {
    const onboarding = await this.$api.website.onboarding.getUserOnboarding();
    commit('SET_USER_ONBOARDING', onboarding);
    return onboarding;
  },
  async putUserOnboarding(_, payload) {
    const onboarding = await this.$api.website.onboarding.putUserOnboarding(payload);
    return onboarding;
  },
  async handleNextStep({ dispatch }, name) {
    const { user } = this.state;
    // Need verification
    const found = user.data.onboardingCourses.find((el) => el.name === name);

    if (!found) return;
    const payload = {
      flow: name,
      step: found.step + 1,
      status: found.status,
    };
    await dispatch('putUserOnboarding', payload);
  },
  async handlePrevStep({ dispatch }, name) {
    const { user } = this.state;

    const found = user.data.onboardingCourses.find((el) => el.name === name);
    if (!found || found.step === 0) return;
    const payload = {
      flow: name,
      step: found.step - 1,
      status: found.status,
    };
    await dispatch('putUserOnboarding', payload);
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
