<template>
  <div v-if="user.data">
    <tglyr-chatbot
      v-if="chatGPTActive && isTaglayerAdmin"
      :messages="gptMessages"
      :loading="gptLoading"
      position="bottom right"
      icon="TaglayerAi"
      title="Taglayer AI"
      placeholder="Ask me anything..."
      initial-message="Hello I am your Taglayer AI assistant. Ask me anything and I will answer your question"
      @message-send="handleChatPrompt"
    />
    <cta-banner
      v-if="authenticatedDomain?.info?.noTracking"
      type="warning"
    >
      <div>
        <span class="mr-2">
          Tracking has been disabled on your website. If you'd like to learn more or need assistance, please contact our sales team.
        </span>
        <tglyr-button
          class="m-2"
          size="mini"
          @click="handleContactSalesHubspot"
        >
          Contact
        </tglyr-button>
      </div>
    </cta-banner>
    <cta-banner v-if="!user.data.emailVerification && showVerificationModal">
      <div class="flex items-center">
        <p class="mr-2">
          👋 Hi {{ user.data.username }}, we noticed that you haven't verified your email yet.
          <b>Verify now</b>
          to access our features and more.
        </p>
        <tglyr-button
          class="m-2"
          size="mini"
          @click="handleResend"
        >
          Resend verification email
        </tglyr-button>
      </div>
      <div
        class="cursor-pointer p-4 absolute top-0 right-0"
        @click="showVerificationModal = false"
      >
        <tglyr-icon
          icon-name="close"
          width="8px"
          height="8px"
        />
      </div>
    </cta-banner>
    <cta-banner v-if="trackingBannerVisible">
      <div>
        <span class="mr-2">
          You have not installed the tracking code on your website yet.
          <b>Taglayer won't work without it.</b>
        </span>
        <tglyr-button
          class="m-2"
          size="mini"
          @click="gotoDocs()"
        >
          Read more
        </tglyr-button>
        <tglyr-button
          class="m-2"
          size="mini"
          @click="gotoTrackingpage()"
        >
          Verify tracking code
        </tglyr-button>
      </div>
      <div
        class="cursor-pointer p-4 right-10 underline text-xs"
        @click="dontRemindMe()"
      >
        I'll do it later
      </div>
      <div
        class="cursor-pointer p-4 absolute right-0"
        @click="showVerificationModal = false"
      >
        <tglyr-icon
          icon-name="close"
          width="8px"
          height="8px"
        />
      </div>
    </cta-banner>
  </div>
  <component :is="layoutKey">
    <slot />
  </component>
</template>

<script setup>
import { computed, ref, inject, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useNotify from '@composables/notify';
import { handleContactSalesHubspot } from '@composables/contact-sales';
import CtaBanner from '@components/banner/cta-banner.vue';
import apiService from '@services/api';

import LayoutNavigation from './_navigation.vue';
import LayoutAuthentication from './_authentication.vue';
import LayoutIsolated from './_isolated.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { notifyError } = useNotify();

const user = computed(() => store.state.user);
const authenticatedDomain = computed(() => store.state.domain.data);
const chatGPTActive = computed(() => store.getters['website/integrations/hasIntegration']('openai'));
const isTaglayerAdmin = computed(() => store.state.user.data.taglayerAdministrator);

const layoutKey = computed(() => {
  switch (route.meta.layout) {
    case 'authentication':
      return LayoutAuthentication;
    case 'navigation':
      return LayoutNavigation;
    case 'isolated':
      return LayoutIsolated;
    default:
      return LayoutNavigation;
  }
});
const showVerificationModal = ref(false);
const gptMessages = ref([]);
const gptLoading = ref(false);

const $notifyPosition = inject('$notify-position');

$notifyPosition('top right');

const trackingBannerVisible = computed(
  () =>
    user.value.data.emailVerification &&
        showVerificationModal.value &&
        !authenticatedDomain.value?.verified &&
        !sessionStorage.getItem('trackingcodeHide') &&
        !window.location.href.includes('/settings/domain/info'),
);

const handleResend = async () => {
  if (user.value.data) {
    const data = { email: user.value.data.email };
    await store.dispatch('user/resendVerification', data);
    showVerificationModal.value = false;
  }
};

const gotoTrackingpage = () => {
  router.push('/settings/domain/info');
};

const gotoDocs = () => {
  window.open('https://docs.taglayer.com/getting-started/installation', '_blank');
};

const dontRemindMe = () => {
  showVerificationModal.value = false;
  sessionStorage.setItem('trackingcodeHide', true);
};

const handleChatPrompt = async (chatMessage) => {
  gptLoading.value = true;
  gptMessages.value.push({
    role: 'user',
    content: chatMessage,
  });
  try {
    const response = await apiService.website.openai.generateResponeOnPrompt({
      chat: gptMessages.value,
    });
    gptMessages.value.push(response);
  } catch (error) {
    notifyError(error.message);
  } finally {
    gptLoading.value = false;
  }
};

onMounted(async () => {
  await store.dispatch('website/integrations/fetch');
});

</script>
