import axios from '@services/api/axios';

export const fetchTemplates = async () => {
  const response = await axios.get('/email/template');

  return response.data;
};

export const updateTemplate = async (payload) => {
  const body = {
    name: payload.name,
    elements: payload.elements,
    globals: payload.globals,
    notes: payload.notes,
  };
  const response = await axios.put(`/email/template/${payload._id}`, body);

  return response.data;
};
export const createTemplate = async (payload) => {
  const body = {
    name: payload.name,
    elements: payload.elements || [],
    globals: payload.globals || {},
    notes: payload.notes || '',
  };
  const response = await axios.post('/email/template', body);

  return response.data;
};
export const updateScreenshot = async (id) => {
  const response = await axios.put(`/email/template/screenshot/${id}`);

  return response.data;
};
export const removeTemplate = async (id) => {
  const response = await axios.delete(`/email/template/${id}`);

  return response.data;
};

export const testEmailTemplate = async (payload) => {
  const response = await axios.post('/email/template/test', payload);
  return response.data;
};

export const getTemplateById = async (id) => {
  const response = await axios.get(`/email/template/id/${id}`);
  return response.data;
};
