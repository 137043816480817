const _state = {
  loading: false,
  interests: [],
};

const getters = {
  isLoading: (state) => state.loading,
  all: (state) => state.interests,
  tags: (state) => state.interests.map((interest) => interest.tags).flat(),
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_INTERESTS: (state, interests) => {
    state.interests = interests;
  },
  SET_LOADING: (state, status) => {
    state.loading = status;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);
    const interests = await this.$api.interests.fetchInterests();
    commit('SET_INTERESTS', interests);
    commit('SET_LOADING', false);

    return interests;
  },
  async fetchWithoutLoader({ commit }) {
    const interests = await this.$api.interests.fetchInterests();
    commit('SET_INTERESTS', interests);
    return interests;
  },
  async create({ dispatch }, { tags, conditions }) {
    const interest = await this.$api.interests.createInterest({ tags, conditions });
    await dispatch('fetchWithoutLoader');

    return interest;
  },
  async update({ dispatch }, { id, data }) {
    const interest = await this.$api.interests.updateInterest(id, data);
    await dispatch('fetchWithoutLoader');

    return interest;
  },
  async remove({ dispatch }, id) {
    const interest = await this.$api.interests.removeInterest(id);
    await dispatch('fetchWithoutLoader');

    return interest;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
