const _state = {
  list: [],
  filtered: [],
};

/* eslint-disable no-param-reassign */
const getters = {
  businesses: (state) => state.list,
  filtered: (state) => state.filtered,
};

const mutations = {
  SET_LIST(state, businesses) {
    state.list = businesses;
  },
  SET_FILTERED(state, businesses) {
    state.filtered = businesses;
  },
};

/* eslint-disable no-param-reassign */
const actions = {
  async setData({ commit }, data) {
    commit('SET_LIST', data);
  },
  search({ commit, state }, query) {
    const filtered = state.list.filter((business) => business.domain && new RegExp(query, 'i').test(business.domain));
    commit('SET_FILTERED', filtered);

    return filtered;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
