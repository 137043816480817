import { computed } from 'vue';
import { useRouter } from 'vue-router';
import createStore from '@store';

const store = createStore;

export const handleHover = (e) => {
  let menuItm = e.target;
  if (!e.target.classList.contains('menuHoverShadow')) {
    menuItm = e.target.parentElement.parentElement;
  }
  const rect = menuItm.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  menuItm.style.setProperty('--x', `${x}px`);
  menuItm.style.setProperty('--y', `${y}px`);
};

export const useNavigation = () => {
  const router = useRouter();
  const authenticatedUser = computed(() => store.state.user.data);
  const navitems = [
    {
      group: 'main',
      items: [
        {
          to: '/dashboard',
          text: 'Dashboard',
          icon: 'Heartbeat',
        },
        {
          to: '/audience',
          text: 'Audiences',
          icon: 'MultipleNeutral2',
          children: [
            {
              id: 'Overview',
              to: '/audience/overview',
              text: 'Audiences',
              group: 'Visitors',
            },
            {
              id: 'contacts',
              to: '/audience/contacts',
              text: 'Contacts',
              group: 'Visitors',
            },
            {
              id: 'business_visits',
              to: '/audience/business-visits',
              text: 'Business visits',
              group: 'Visitors',
            },
          ],
        },
        {
          to: '/journeys',
          text: 'Journeys',
          icon: 'Hierarchy6',
        },
        {
          to: '/channels',
          text: 'Channels',
          icon: 'LayoutDashboard',
          children: [
            {
              id: 'website_cases',
              to: '/channels/website/cases',
              text: 'Website cases',
              group: 'Website',
            },
            {
              id: 'email_templates',
              to: '/channels/email/templates',
              text: 'Email templates',
              group: 'Website',
            },
          ],
        },
        {
          to: '/performance',
          text: 'Performance',
          icon: 'AnalyticsBars',
          children: [
            {
              id: 'Conversions',
              to: '/performance/website/conversions',
              text: 'Conversions',
              group: 'Website',
            },
            {
              id: 'conversion_predictions',
              to: '/performance/website/conversion-predictions',
              text: 'Conversion predictions',
              group: 'Website',
            },
            {
              id: 'Interests',
              to: '/performance/website/interests',
              text: 'Interests',
              group: 'Website',
            },
            {
              id: 'Variations',
              to: '/performance/website/ab-tests',
              text: 'A/B tests',
              group: 'Website',
            },
            {
              id: 'Conditions',
              to: '/performance/website/conditions',
              text: 'Conditions',
              group: 'Website',
            },
            {
              id: 'Rfm',
              to: '/performance/website/rfm',
              text: 'RFM',
              group: 'Website',
            },
            /*
            {
              id: 'Recommendations',
              to: '/performance/website/recommendations',
              text: 'Recommendations',
              group: 'Website',
            },
            */
            {
              id: 'Cases',
              to: '/performance/channels/cases',
              text: 'Website cases',
              group: 'Channels',
            },
            {
              id: 'Emails',
              to: '/performance/channels/email',
              text: 'Emails',
              group: 'Channels',
            },
            {
              id: 'Notifications',
              to: '/performance/channels/notifications',
              text: 'Notifications',
              group: 'Channels',
            },
            {
              id: 'WhatsApp',
              to: '/performance/channels/whatsapp',
              text: 'WhatsApp',
              group: 'Channels',
            },
          ],
        },
      ],
    },
    {
      group: 'footer',
      items: [
        {
          to: '/product',
          text: 'Products',
          icon: 'LayersStacked',
          children: [
            {
              id: 'product_product',
              to: '/product/items',
              text: 'Products',
            },
            {
              id: 'product_collection',
              to: '/product/collections',
              text: 'Collections',
            },
            {
              id: 'product_feed',
              to: '/product/feeds',
              text: 'Feeds',
            },
          ],
        },
        {
          to: '/media',
          text: 'Media',
          icon: 'PictureLandscape',
        },
        {
          to: '/integrations',
          text: 'Integrations',
          icon: 'ModuleThree2',
        },
        {
          to: '/settings',
          text: 'Settings',
          icon: 'cog',
          children: [
            {
              id: 'Profile',
              to: '/settings/account/profile',
              text: 'Profile',
              group: 'Account',
            },
            {
              id: 'privacy_emails',
              to: '/settings/account/privacy',
              text: 'Privacy & emails',
              group: 'Account',
            },
            {
              id: 'Companies',
              to: '/settings/account/companies',
              text: 'Companies',
              group: 'Account',
            },
            {
              id: 'domain_info',
              to: '/settings/domain/info',
              text: 'Info',
              group: 'Domain',
            },
            ...(authenticatedUser.value.taglayerAdministrator ? [
              {
                id: 'domain_admin',
                to: '/settings/domain/admin',
                text: 'Admin',
                group: 'Domain',
              },
            ] : []),
            {
              id: 'domain_subscription',
              to: '/settings/domain/subscription',
              text: 'Subscription',
              group: 'Domain',
            },
            {
              id: 'domain_activity',
              to: '/settings/domain/activity',
              text: 'Activity',
              group: 'Domain',
            },
            {
              id: 'capture_data_types',
              to: '/settings/domain/capture-data-types',
              text: 'Capture data types',
              group: 'Domain',
            },
            {
              id: 'export_capture_data',
              to: '/settings/domain/export-capture-data',
              text: 'Export capture data',
              group: 'Domain',
            },
            {
              id: 'company_info',
              to: '/settings/company/info',
              text: 'Info',
              group: 'Company',
            },
            {
              id: 'Team',
              to: '/settings/company/team',
              text: 'Team',
              group: 'Company',
            },
            {
              id: 'Domains',
              to: '/settings/company/domains',
              text: 'Domains',
              group: 'Company',
            },
            {
              id: 'Logout',
              text: 'Logout',
              logoutRoute: true,
            },
          ],
        },
        {
          text: 'Help',
          to: '/help',
          icon: 'questionCircle',
          children: [
            {
              id: 'Helpdocs',
              text: 'Documentation',
              to: '/help/documentation',
              group: 'Help',
            },
            {
              id: 'Tutorials',
              to: '/help/tutorials',
              text: 'Tutorials',
              group: 'Help',
            },
            {
              id: 'contact',
              to: '/help/contact',
              text: 'Contact us',
              group: 'Help',
            },
          ],
        },
        {
          text: authenticatedUser.value.username,
          icon: authenticatedUser.value.image,
          avatar: true,
          to: '/settings/account/profile',
        },
      ],
    },
  ];
  const active = computed(() =>
    navitems
      .reduce((acc, next) => {
        acc.push(...next.items);
        return acc;
      }, [])
      .find((obj) => router.currentRoute.value.path.startsWith(obj.to)));
  const subnavitems = computed(() => {
    if (active.value && active.value.children && active.value.children.length) return active.value.children;

    return null;
  });

  const subnavActive = computed(() => subnavitems.value.find((obj) => router.currentRoute.value.path.includes(obj.to)));

  return {
    navitems,
    subnavitems,
    active,
    subnavActive,
  };
};
