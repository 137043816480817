const _state = {
  capturedata: [],
  loading: false,
};

const getters = {
  capturedata: (state) => state.capturedata,
  isIdentifier: (state) => (captureId) => state.capturedata.some((item) => item._id === captureId && item.isIdentifier),
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_DATA(state, captureData) {
    state.capturedata = captureData;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetch({ commit }) {
    commit('SET_LOADING', true);
    const captureData = await this.$api.website.captureData.fetchCaptureData();
    commit('SET_DATA', captureData);
    commit('SET_LOADING', false);

    return captureData;
  },
  async createCaptureData({ dispatch }, payload) {
    const newCaptureData = await this.$api.website.captureData.createCaptureData(payload);
    await dispatch('fetch');
    return newCaptureData;
  },
  async fetchDomainCaptureData(_, lastVisit = null) {
    const data = await this.$api.website.captureData.fetchDomainCaptureData(lastVisit);
    return data;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
