import moment from 'moment';
import { tglyrPrice } from '@helpers/numbers/numbers.helper';

const getWidgetData = (totalConversions) =>
  totalConversions.reduce(
    (acc, item) => {
      // Add to total
      acc.total += item.total;
      // acc.value += item.value;

      return acc;
    },
    {
      total: 0,
      value: 0,
    },
  );

const getWidgetChart = (dailyTotalConversions, key) => ({
  data: dailyTotalConversions.map((day) =>
    day.data.reduce((acc, item) => acc + (item[key] || 0), 0)),
});

const getInteractedWidgetChart = (dailyTotalConversions, dailyTaglayerConversions) => ({
  series: [
    {
      name: 'Interacted conversions rate',
      data: dailyTotalConversions.map((day, index) => {
        const dailyInteracted = dailyTaglayerConversions[index].data.reduce((acc, item) => acc + item.total || 0, 0);
        const dailyConverted = day.data.reduce((acc, item) => acc + item.total || 0, 0);
        return dailyConverted ? dailyInteracted / dailyConverted : 0;
      }),
    },
  ],
});

const getEndGoalConversions = (conversions, dailyTotalConversions, key) => dailyTotalConversions.map((day) => day.data.reduce((acc, item) => {
  const foundConversion = conversions.find((conv) => conv._id === item.conversionId);
  if (!foundConversion || !foundConversion.isEndGoal) {
    return acc;
  }
  let temp = acc;
  temp += item[key];
  return temp;
}, 0));

const createEndGoalConversionValueSeries = (conversions, dailyConversions) => ({
  data: getEndGoalConversions(conversions, dailyConversions, 'value'),
});

export default {
  calculateTable({ conversions, totalConversions, taglayerConversions }) {
    return conversions.reduce((acc, conversionItem) => {
      // Something wrong with this conversionData i think?
      const conversionData = totalConversions.find((item) => item.conversionId === conversionItem._id);
      const interactedData = taglayerConversions.find((item) => item.conversionId === conversionItem._id);

      acc.push({
        ...conversionItem, // unwind conversion record for 'edit' button
        hasCapturing: conversionItem.capturing.length > 0,
        total: conversionData?.total && conversionData.total,
        interacted: interactedData?.total && interactedData.total,
        // conversionData does not have key interacted
        interactedRate: conversionData && interactedData ? interactedData.total / conversionData.total || 0 : 0,
        // Dynamic conversion value from analytics
        conversionValue: conversionData?.value ?? 0,
        interactedValue: interactedData?.value ?? 0,
      });

      return acc;
    }, []);
  },
  calculateWidgets({ conversions, totalConversions, taglayerConversions, dailyTotalConversions, dailyTaglayerConversions }) {
    const widgetData = getWidgetData(totalConversions);
    const interactedWidgetData = getWidgetData(taglayerConversions);
    const xasCategories = dailyTotalConversions.map((day) => moment(day.date).format('D MMM YYYY'));

    const createConversionSeries = (listOfConversions) => ([
      {
        name: 'Amount of conversions',
        ...getWidgetChart(listOfConversions, 'total'),
      },
    ]);

    const revenueChart = createEndGoalConversionValueSeries(conversions, dailyTotalConversions);

    return [
      {
        key: 'revenue',
        name: 'Revenue generated',
        value: getEndGoalConversions(conversions, dailyTotalConversions, 'total')?.reduce((acc, item) => acc + item, 0),
        chart: {
          xasCategories,
          series: [
            {
              name: 'Revenue',
              ...revenueChart,
            },
          ],
        },
        tags: [
          {
            text: `${tglyrPrice(revenueChart.data.reduce((acc, item) => acc + item, 0))}`,
            type: 'success',
          },
        ],
        colors: ['#8b5cf6'],
        format: 'price',
      },
      {
        key: 'total',
        name: 'Total conversions',
        value: widgetData.total,
        chart: {
          xasCategories,
          series: createConversionSeries(dailyTotalConversions),
        },
        format: 'number',
        colors: ['#8b5cf6'],
      },
      {
        key: 'interacted',
        name: 'Taglayer conversions',
        value: interactedWidgetData.total,
        chart: {
          xasCategories,
          series: createConversionSeries(dailyTaglayerConversions),
        },
        format: 'number',
        colors: ['#8b5cf6'],
      },
      {
        key: 'interacted_percentage',
        name: 'Taglayer percentage',
        value: widgetData.total && interactedWidgetData.total ? interactedWidgetData.total / widgetData.total : 0,
        chart: {
          xasCategories,
          ...getInteractedWidgetChart(dailyTotalConversions, dailyTaglayerConversions),
        },
        format: 'percent',
        colors: ['#8b5cf6'],
      },
    ];
  },
};
