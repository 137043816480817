export default [
  {
    path: 'account',
    children: [
      {
        path: '',
        redirect: '/settings/account/profile',
      },
      {
        path: 'profile',
        component: () => import('./profile/profile-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'privacy',
        component: () => import('./privacy/privacy-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'companies',
        component: () => import('./companies/companies-page.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
