<template>
  <navigation-switcher
    :visible="showSwitcher"
    @update:visible="showSwitcher = isAllowedToSwitch ? $event : showSwitcher"
  />
  <div
    class="flex flex-col bg-white border-r overflow-hidden w-18 md:hover:w-84 z-20 h-full transition-all ease-in-out duration-300 min-w-18"
    :class="!isAllowedToSwitch ? 'max-w-18' : 'xl:min-w-84 max-w-96'"
    @mouseenter="navigationIsCollapsed = false"
    @mouseleave="navigationIsCollapsed = true"
  >
    <!-- Navigation switcher -->
    <div
      data-test="navigation-switcher"
      class="flex flex-col shrink-0 justify-center h-20 px-4 w-full bg-white border-b"
    >
      <div
        v-if="authenticatedCompany && authenticatedDomain"
        class="flex w-full bg-white items-center group rounded text-sm font-medium cursor-pointer"
        @click="isAllowedToSwitch ? (showSwitcher = true) : (showSwitcher = false)"
      >
        <tglyr-avatar
          class="rounded-md shrink-0 mr-3"
          size="2.5rem"
          :image="authenticatedCompany.image"
          :name="authenticatedCompany.name"
        />
        <div class="flex flex-col w-full truncate">
          <span class="text-sm text-gray-800 font-semibold leading-snug truncate">
            {{ authenticatedCompany.name }}
          </span>
          <span class="text-sm text-gray-600 leading-tight truncate">
            {{ authenticatedDomain.domain }}
          </span>
        </div>
        <div class="shrink-0 flex flex-col mx-2 text-gray-700 group-hover:text-blue-500">
          <tglyr-icon
            icon-name="ArrowUp1"
            custom-icon-class="text-gray-600 h-2.5 w-2.5 mb-0.5 group-hover:text-blue-500"
          />
          <tglyr-icon
            icon-name="ArrowDown1"
            custom-icon-class="text-gray-600 h-2.5 w-2.5 mt-0.5 group-hover:text-blue-500"
          />
        </div>
      </div>
    </div>
    <!-- Nav & Subnav items -->
    <div
      class="flex h-full xl:w-full min-w-18"
      :class="{ 'w-full': !navigationIsCollapsed }"
    >
      <div
        class="flex flex-col justify-between shrink-0 p-4 transition-all ease-in-out duration-300 gap-3"
        :class="(subnavitems && !hoverOverMainNav) || !isAllowedToSwitch ? 'border-r w-18' : 'w-full'"
        @click="hoverOverMainNav = false"
      >
        <div
          v-for="group in navitems"
          :key="group.id"
          class="flex flex-col gap-6"
          @mouseenter="changeHoverOverMainNav(true)"
          @mouseleave="changeHoverOverMainNav(false)"
        >
          <setup-item
            v-if="group.group === 'footer' && !loading"
            :menu-is-collapsed="menuIsCollapsedOnSmallerScreen"
            :is-allowed-to-switch="isAllowedToSwitch"
          />
          <navigation-item
            v-for="link in group.items"
            :key="link.to"
            :to="link.to"
            :active="active && active.to === link.to"
            :icon="link.icon"
            :name="link.text"
            :avatar="link.avatar"
            :has-children="!!link.children"
          />
        </div>
      </div>
      <aside
        v-if="subnavitems && !hoverOverMainNav"
        class="w-full px-4 py-6 truncate"
      >
        <navigation-subnav
          :items="subnavitems"
          :active="subnavActive"
        />
      </aside>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';

import { useNavigation } from '../../composables/nav';

import NavigationItem from './navigation-item.vue';
import NavigationSubnav from './navigation-subnav.vue';
import NavigationSwitcher from './navigation-switcher.vue';
import SetupItem from './setup-item.vue';

const store = useStore();
const router = useRouter();
const loading = ref(false);

const { navitems, subnavitems, active, subnavActive } = useNavigation();

const showSwitcher = ref(false);
const hoverOverMainNav = ref(false);
const navigationIsCollapsed = ref(false);

const isAllowedToSwitch = computed(
  () => Object.keys(router.currentRoute.value.params).length === 0 && Object.values(router.currentRoute.value.params).length === 0,
);

const authenticatedDomain = computed(() => store.getters['auth/domain']);
const authenticatedCompany = computed(() => store.getters['auth/company']);
const authenticatedUser = computed(() => store.getters['auth/user']);

const menuIsCollapsedOnSmallerScreen = computed(() => navigationIsCollapsed.value && window?.innerWidth < 1200);

const changeHoverOverMainNav = (e) => {
  if (subnavitems.value) hoverOverMainNav.value = e;
  else hoverOverMainNav.value = true;
};

onMounted(async () => {
  loading.value = true;
  await Promise.all([
    store.dispatch('website/conversions/fetch'),
    store.dispatch('website/cases/fetch'),
    store.dispatch('website/journeys/fetch'),
    store.dispatch('interests/fetch'),
    store.dispatch('analytics/fetch'),
  ]);
  Sentry.setUser({
    id: String(authenticatedUser.value._id),
    username: authenticatedUser.value.username,
    email: authenticatedUser.value.email,
    domain: authenticatedDomain.value.domain,
  });

  Sentry.setTags({
    username: authenticatedUser.value.username,
    email: authenticatedUser.value.email,
    domain: authenticatedDomain.value.domain,
    taglayerAdministrator: authenticatedUser.value.taglayerAdministrator,
  });

  loading.value = false;
});

</script>
