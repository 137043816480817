import axios from '@services/api/axios';

export const fetchConversions = async () => {
  const response = await axios.get('/website/conversion/domain');
  return response.data;
};

export const createConversion = async (payload) => {
  const response = await axios.post('/website/conversion/create', payload);
  return response.data;
};

export const updateConversion = async (id, payload) => {
  const response = await axios.put(`/website/conversion/update/${id}`, payload);
  return response.data;
};

export const removeConversion = async (id) => {
  const response = await axios.delete(`/website/conversion/remove/${id}`);

  return response.data;
};
