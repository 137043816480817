const routes = [
  {
    path: 'contact/:id',
    name: 'profile-details',
    component: () => import('./profiles/profile-details.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'contacts',
    children: [
      {
        path: '',
        name: 'contacts-overview',
        component: () => import('./contacts-overview.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id',
        name: 'contacts-details',
        component: () => import('./contacts-details.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
