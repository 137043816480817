export default [
  {
    path: '/media',
    name: 'media',
    component: () => import('./media-page.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
