import emailRoutes from './email/email-routes';
import websiteRoutes from './website/website-routes';

export default [
  {
    path: '/channels',
    children: [
      {
        path: '',
        redirect: '/channels/website/cases',
      },
      ...emailRoutes,
      ...websiteRoutes,
    ],
  },
];
