<template>
  <div
    v-if="completedStepsCount < steps.length"
    v-click-outside="handleClickOutside"
    @click="panelShown = !panelShown"
  >
    <div
      class="flex p-2 gap-8 items-center w-full cursor-pointer rounded truncate transition-all ease-linear duration-300 menuHoverShadow"
      :class="
        panelShown ? 'bg-blue-50 shadow-inner mouse-cursor-gradient-tracking' : 'hover:bg-gray-50 hover:shadow-inner mouse-cursor-gradient-tracking-hover'
      "
      @mousemove="handleHover"
    >
      <div class="flex flex-col w-full gap-2">
        <tglyr-slider
          v-model="completedStepsCount"
          :max="steps.length"
          type="success"
          :show-button="false"
        />
        <div class="flex flex-row gap-8">
          <tglyr-icon
            icon-name="Rocket"
            :custom-icon-class="['h-5 w-5', panelShown ? 'text-blue-500' : 'text-gray-500']"
          />
          <div class="flex flex-row justify-between w-full truncate">
            <span
              class="text-sm font-medium"
              :class="panelShown ? 'text-blue-500' : 'text-gray-500'"
            >
              Setup
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <setup-panel
    :show="panelShown"
    :steps="steps"
    :menu-is-collapsed="menuIsCollapsed"
    :is-allowed-to-switch="isAllowedToSwitch"
    @close="panelShown = false"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { handleHover } from '@composables/nav';
import SetupPanel from './setup-panel.vue';

defineProps({
  menuIsCollapsed: {
    type: Boolean,
    default: false,
  },
  isAllowedToSwitch: {
    type: Boolean,
    required: true,
  },
});

const panelShown = ref(false);
const store = useStore();

const steps = computed(() => {
  const user = store.state.user.data;
  const domain = store.state.domain.data;
  const { website } = store.state;

  return [
    {
      label: 'Verify e-mailadres',
      name: 'verified_email',
      done: user.emailVerification,
      url: '/settings/account/profile',
    },
    {
      label: 'Install tracking code',
      name: 'installed_tracking',
      done: domain?.verified ?? false,
      url: '/settings/domain/info',
    },
    {
      label: 'Verify installed tracking code',
      name: 'verified_tracking',
      done: domain?.verified ?? false,
      url: '/settings/domain/info',
    },
    {
      label: 'Enable 2 Factor Authentication',
      name: 'enabled_2fa',
      done: user.two_factor_enabled,
      url: '/settings/account/profile',
    },
    {
      label: 'Create conversions',
      name: 'created_conversions',
      done: website.conversions.list.length > 0,
      url: '/performance/website/conversions',
    },
    {
      label: 'Create audience list',
      name: 'created_audience',
      done: website.audience.list.length > 0,
      url: '/audience/overview',
    },
    {
      label: 'Setup interests',
      name: 'setup_interests',
      done: store.state.interests.interests.length > 0,
      url: '/performance/website/interests',
    },
    {
      label: 'Create a website case',
      name: 'created_case',
      done: website.cases.list.length > 0,
      url: '/channels/website/cases',
    },
    {
      label: 'Create a journey',
      name: 'created_journey',
      done: website.journeys.list.length > 0,
      url: '/journeys/overview',
    },
    {
      label: 'Set a journey live',
      name: 'journey_live',
      done: website.journeys.list.some((journey) => journey.enabled),
      url: '/journeys/overview',
    },
  ];
});

const completedStepsCount = computed(() => steps.value.filter((step) => step.done === true).length);

const handleClickOutside = () => {
  panelShown.value = false;
};

</script>
