import api from '@services/api';
/*  eslint-disable no-useless-catch */
export default function start(store) {
  try {
    // eslint-disable-next-line no-param-reassign
    store.$api = api;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
