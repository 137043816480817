const _state = {
  step: 1,
  saving: false,
  removing: false,
  identifier: null,
  validateForm: () => {},
  valid: false,
  conversion: {
    type: 'selector',
    name: '',
    action: '',
    selector: '',
    conditions: [],
    capturing: [],
    isEndGoal: false,
    isLogin: false,
  },
};

const getters = {
  '': (state) => state,
  'conversion': (state) => state.conversion,
  'saving': (state) => state.saving,
  'removing': (state) => state.removing,
  'identifier': (state) => state.identifier,
  'step': (state) => state.step,
  'type': (state) => state.conversion.type,
  'capturing': (state) => state.conversion.capturing,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_SAVING: (state, saving) => {
    state.saving = saving;
  },
  SET_STEP: (state, step) => {
    state.step = step;
  },
  SET_REMOVING: (state, removing) => {
    state.removing = removing;
  },
  SET_IDENTIFIER: (state, identifier) => {
    state.identifier = identifier;
  },
  SET_CONVERSION: (state, conversion) => {
    state.conversion = conversion;
  },
  SET_CAPTURING: (state, capturing) => {
    state.conversion.capturing = capturing;
  },
  SET_NAME: (state, name) => {
    state.conversion.name = name;
  },
  SET_TYPE: (state, type) => {
    state.conversion.type = type;
  },
  SET_IS_END_GOAL: (state, isEndGoal) => {
    state.conversion.isEndGoal = isEndGoal;
  },
  SET_IS_LOGIN: (state, isLogin) => {
    state.conversion.isLogin = isLogin;
  },
  SET_VALIDATEFORM: (state, validateform) => {
    state.validateForm = validateform;
  },
};
/* eslint-disable no-param-reassign */

const actions = {
  // Saves the conversion and POSTs it to the API
  async save({ dispatch }, conversion) {
    // Send to API here
    await dispatch('website/conversions/createConversion', conversion, { root: true });
  },
  // Removes a capture value of a draft
  async remove({ commit }) {
    commit('SET_REMOVING', true);
    //
    commit('SET_REMOVING', false);
  }, // Resets the state to its default configuration
  resetState: ({ commit }) => {
    commit('SET_STEP', 1);
    commit('SET_IDENTIFIER', null);
    commit('SET_CONVERSION', {
      type: 'selector',
      name: '',
      action: '',
      selector: '',
      conditions: [],
      capturing: [],
      isEndGoal: false,
      isLogin: false,
    });
  },
  setConversion({ commit }, conversion) {
    commit('SET_CONVERSION', conversion);
  },
  setName({ commit }, name) {
    commit('SET_NAME', name);
  },
  setType({ commit }, type) {
    commit('SET_TYPE', type);
  },
  setGoal({ commit }, goalType) {
    switch (goalType) {
      case 'end':
        commit('SET_IS_END_GOAL', true);
        commit('SET_IS_LOGIN', false);
        break;
      case 'login':
        commit('SET_IS_END_GOAL', false);
        commit('SET_IS_LOGIN', true);
        break;
      case 'normal':
      default:
        commit('SET_IS_END_GOAL', false);
        commit('SET_IS_LOGIN', false);
    }
  },
  addCapturing({ commit, state }, capturing) {
    const temp = [...state.conversion.capturing];
    temp.push(capturing);
    commit('SET_CAPTURING', temp);
  },
  updateCapture({ commit, state }, { captureData, index }) {
    const temp = [...state.conversion.capturing];
    temp[index] = captureData;
    commit('SET_CAPTURING', temp);
  },
  removeCapturing({ commit, state }, index) {
    const temp = [...state.conversion.capturing];
    temp.splice(index, 1);
    commit('SET_CAPTURING', temp);
  },
  setSaving({ commit }, value) {
    commit('SET_SAVING', value);
  },
  async validateStep({ state }) {
    return state.validateForm();
  },
};
export default {
  state: _state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
