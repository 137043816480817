const _state = {
  templates: [],
  phoneNumbers: [],
};

const getters = {
  phoneNumbers: (state) => state.phoneNumbers,
  templates: (state) => state.templates,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_PHONE_NUMBERS(state, numbers) {
    state.phoneNumbers = numbers;
  },
};
/* eslint-enable no-param-reassign */

/*  eslint-disable no-useless-catch */
const actions = {
  async fetchTemplates({ commit }) {
    try {
      const templates = await this.$api.website.whatsapp.fetchTemplates();
      commit('SET_TEMPLATES', templates);
      return templates;
    } catch (error) {
      throw error;
    }
  },
  async fetchPhoneNumbers({ commit }) {
    try {
      const phoneNumbers = await this.$api.website.whatsapp.fetchPhoneNumbers();
      commit('SET_PHONE_NUMBERS', phoneNumbers);
      return phoneNumbers;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
