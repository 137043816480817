import moment from 'moment';
import { tglyrPrice } from '@helpers/numbers/numbers.helper';

const getStageName = (stageId, stagesMap) => {
  const stage = stagesMap.find((stg) => String(stg.stageId) === stageId);
  if (!stage || !stage.tree) return { label: 'Unknown', tree: [] };
  return {
    label: stage.tree[stage.tree.length - 1]?.content,
    tree: stage.tree.map((stg) => stg.name),
  };
};

const createChart = ({ name, data }) => {
  const parseData = (key) => data.reduce((prev, next) => {
    const calculatedData = next.data.reduce((preceding, upcoming) => preceding + upcoming[key], 0);
    prev.push(calculatedData);
    return prev;
  }, []);

  const chartConfig = {
    xasCategories: data.map((day) => moment(day.date).format('D MMM YYYY')),
    series: [
      {
        name,
        data: parseData('total'),
      },
    ],
  };
  // Same problem here as in manual. Can't add valuta
  // if (withConversionValue) {
  //   chartConfig.series.push({
  //     name: `${name} value`,
  //     data: parseData('value'),
  //   });
  // }
  return chartConfig;
};

const formatForChart = (data, field) =>
  data.reduce((prev, upcoming) => {
    prev.push({
      date: upcoming.date,
      data: upcoming.data.reduce((acc, journey) => {
        const total = journey.stages.reduce((stageAcc, stage) => stageAcc + stage.data[field], 0);
        acc.push({ total });
        return acc;
      }, []),
    });

    return prev;
  }, []);

const formatConversionsForChart = (data, filters) =>
  data.reduce((prev, upcoming) => {
    prev.push({
      date: upcoming.date,
      data: upcoming.data.filter((conv) => (filters.length < 1 ? true : filters.indexOf(conv.conversionId) > -1)),
    });

    return prev;
  }, []);

export default {
  calculateTable({ filters, analytics, stagesMap, journeys }) {
    return analytics.journeys
      .map((journey) => {
        const journeyRecord = journeys.find((jrney) => String(jrney._id) === journey.journeyId);
        if (!journeyRecord) return null;

        const conversionsData = analytics.conversions_per_email
          .filter((conv) => (filters.length < 1 ? true : filters.indexOf(conv.conversionId) > -1))
          .reduce((data, conv) => {
            conv.journeys
              .filter((convJourney) => convJourney.stageId === conv.stageId)
              .forEach((convJourney) => {
                convJourney.stages.forEach((stage) => {
                  const found = data.find((entry) => entry.stageId === stage.stageId);
                  if (found) {
                    found.total += stage.total;
                    // found.conversionsValue += stage.value;
                  } else data.push({ ...stage });
                });
              });
            return data;
          }, []);

        const stages = journey.stages.map((stage) => ({
          ...stage,
          ...getStageName(stage.stageId, stagesMap),
          conversions: 0,
          // conversionsValue: 0,
        }));

        conversionsData.forEach((stage) => {
          const existing = stages.find((substg) => substg.stageId === stage.stageId);
          if (existing) {
            existing.conversions = stage.total;
            // existing.conversionsValue = stage.conversionsValue;
          }
        });

        const result = {
          name: journeyRecord.name,
          journeyId: journeyRecord._id,
          stages,
        };

        // sum all fields via reduce
        Object.keys(stages[0])
          .filter((key) => key !== 'name')
          .forEach((key) => {
            result[key] = stages.reduce((sum, entry) => sum + entry[key], 0);
          });

        return result;
      })
      .filter((entry) => !!entry);
  },

  calculateWidgets({ filters, table, analytics }) {
    const conversionValue = table.reduce((sum, next) => sum + next.conversionsValue, 0);
    return [
      {
        key: 'delivered',
        name: 'Total emails delivered',
        value: table.reduce((sum, next) => sum + next.delivered, 0),
        chart: createChart({ name: 'Delivered', data: formatForChart(analytics.journeys, 'delivered') }),
        colors: ['#f97316'],
        format: 'number',
      },
      {
        key: 'unique_opens',
        name: 'Unique opens',
        value: table.reduce((sum, next) => sum + next.unique_opens, 0),
        chart: createChart({ name: 'Unique opens', data: formatForChart(analytics.journeys, 'unique_opens') }),
        colors: ['#f97316'],
        format: 'number',
      },
      {
        key: 'conversions',
        name: 'Total conversions',
        value: table.reduce((sum, next) => sum + next.conversions, 0),
        tags: conversionValue > 0 ? [
          {
            type: 'success',
            text: tglyrPrice(conversionValue),
          },
        ] : [],
        chart: createChart({ name: 'Conversions', data: formatConversionsForChart(analytics.conversions_per_email, filters) }),
        colors: ['#f97316', '#311704'],
        format: 'number',
      },
    ];
  },
};
