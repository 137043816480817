/**
 * Retrieve Analytical Data for a Specific Case, Journey, Stage, Substage, ...
 * This function retrieves analytical data, such as total counts, page views, or other metrics,
 * from a structured array of analytics for a given case, journey, stage, .... It supports
 * both top-level and nested data structures, making it versatile for various data retrieval scenarios.
 *
 * @param {Object} obj
 * @param {Array} obj.analytics The entire array of analytics of the respective case/journey/notification/...
 * @param {String} obj.analyticValueName The name of the wanted analyticalstat
 * @param {Object} obj.channel The current case/journey/notification
 * @param {String} obj.channelIdName The name of the channel's id (stageId, journeyId, ...)
 * @param {String} obj.childrenName Name of the children of current data (stage -> substages)
 * @param {Number} obj.childId Id of the current child. Used for retrieving analytical data of the child
 * @param {String} obj.resultValueName Name of the result (total/count/pageviews/...)
 * @param {String} obj.customKey Custom key to select data if childId is set, else it uses the channelIdName
 * @returns {Number} Amount of wanted analytical value.
 */
export const getAnalyticValue = ({
  analytics,
  analyticValueName,
  channel,
  channelIdName,
  childrenName,
  childId,
  resultValueName,
  customKey = null,
}) => {
  const analyticEntry = analytics[analyticValueName]?.find((item) => item[channelIdName] === channel[channelIdName]);
  if (!analyticEntry) return 0;
  if (childId) {
    const data = analyticEntry[childrenName].find((entry) => entry[customKey || channelIdName] === childId);
    if (!data) return 0; // (sub)stage can have had no interactions
    return data[resultValueName] ?? 0;
  }
  return analyticEntry[resultValueName] ?? 0;
};

/**
 * Calculates the total amount of conversions of a Case/journey/stage/....
 * If a conversion exists with a static value this will be taken into account
 *
 * @param {Object} obj
 * @param {Array} obj.analytics The entire array of analytics of the respective case/journey/notification/...
 * @param {String} obj.analyticValueName Most cases the name will be conversions_per_stage but in edgde cases this will be usefull
 * @param {Object} obj.channel The current case/journey/notification
 * @param {String} obj.channelIdName The name of the channel's id (stageId, journeyId, ...)
 * @param {String} obj.childrenName Name of the children of current data (stage -> substages)
 * @param {Array} obj.filters Current selected conversion filters
 * @param {String} obj.conversionChild Conversion nested data name
 * @param {Number} obj.childId Id of the current child. Used for retrieving analytical data of the child
 * @param {String} obj.customKey Custom key to select data if childId is set, else it uses the channelIdName
 * @returns {Object} Total amount of conversions and conversion value (static).
 */
export const calculateConversions = ({
  analytics,
  analyticsValueName = 'conversions_per_stage',
  channel,
  channelIdName,
  childrenName = null,
  filters,
  conversionChild = 'stages',
  childId = null,
  customKey = null,
}) => {
  let conversionsPerEntry = analytics[analyticsValueName];

  if (filters.length) conversionsPerEntry = conversionsPerEntry?.filter((conversion) => filters.includes(conversion.conversionId));

  if (!conversionsPerEntry) return 0;
  return conversionsPerEntry.reduce(
    (acc, conversion) => {
      const stageConversions = conversion[conversionChild].find((entry) => entry[channelIdName] === (channel[channelIdName] ?? channel._id));

      if (!stageConversions) return acc;

      if (childId) {
        const data = stageConversions[childrenName].find((entry) => entry[customKey || channelIdName] === childId);
        if (data) {
          // (sub)stage can have had no conversions
          const amount = data.count ?? data.total;
          acc.total += amount;
          acc.value += data.value;
        }
        return acc;
      }
      const amount = stageConversions.total ?? stageConversions.count;
      acc.total += amount;
      acc.value += stageConversions.value;

      return acc;
    },
    {
      total: 0,
      value: 0,
    },
  );
};
