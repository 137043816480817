import moment from 'moment';
import utils from '../utils';

const formatVisitActions = (visits) => {
  visits.sort((a, b) => (moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1));
  const oldestEnrichVisit = visits.find((visit) => visit.queryParams && 'tglyr_enrich' in visit.queryParams);

  return visits.flatMap((visitItem) => {
    const actionsClick = visitItem.clickthroughs.map((item) => ({
      ...item,
      actionType: 'clickthrough',
    }));
    const actionsImpression = visitItem.impressions.map((item) => ({
      ...item,
      actionType: 'impression',
    }));
    const actionsConversion = visitItem.conversions.map((item) => ({
      ...item,
      actionType: 'conversion',
    }));

    const enrichAction = (visitItem === oldestEnrichVisit) ? [{ actionType: 'enrich', params: visitItem.queryParams }] : [];
    const hasUTMParams = visitItem.queryParams && ['utm_campaign', 'utm_medium', 'utm_source'].some((key) => key in visitItem.queryParams);
    const utmAction = hasUTMParams ? [
      {
        actionType: 'utm',
        params: visitItem.queryParams,
      },
    ] : [];

    const audienceJoins = visitItem.audienceJoins?.length ? [{ actionType: 'audienceEvent', type: 'join', audiences: visitItem.audienceJoins }] : [];
    const audienceLeaves = visitItem.audienceLeaves?.length ? [{ actionType: 'audienceEvent', type: 'leave', audiences: visitItem.audienceLeaves }] : [];

    return [
      {
        ...visitItem,
        actionType: 'page',
        timeAgoFormatted: moment(visitItem.createdAt).format('DD MMMM [at] HH:mm'),
      },
      ...enrichAction,
      ...utmAction,
      ...actionsImpression,
      ...actionsClick,
      ...actionsConversion,
      ...audienceJoins,
      ...audienceLeaves,
    ];
  });
};

export default {
  formatVisitorData(data) {
    if (!data) return [];

    const sessionsArray = [
      // sessions
      ...data.sessions.map((sessionItem) => {
        const time = moment(sessionItem.updatedAt).diff(moment(sessionItem.createdAt), 'milliseconds');

        return {
          ...sessionItem,
          isMessage: false,
          timeAgoFromNow: moment(sessionItem.createdAt).fromNow(),
          timeAgoFormatted: moment(sessionItem.createdAt).format('DD MMMM [at] HH:mm'),
          timeSpent: time > 3600000 ? moment.utc(time).format('hh:mm:ss') : moment.utc(time).format('mm:ss'),
          time,
          visitActions: formatVisitActions(sessionItem.visit_data),
          // Not sure if necessary but just in case i check for all business in a session
          business: sessionItem.visit_data.filter((visit) => visit.reverseDNSdomain !== null).map((visit) => visit.reverseDNSdomain),
          timestamp: sessionItem.createdAt,
        };
      }),
      // messages
      ...utils.formatMessageData(data.messages),
    ];

    sessionsArray.sort((a, b) => (moment(a.timestamp).isBefore(moment(b.timestamp)) ? 1 : -1));
    return sessionsArray;
  },
};
