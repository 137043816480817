import axios from '@services/api/axios';

import * as auth from './modules/auth';
import * as billing from './modules/billing';
import * as user from './modules/user';
import * as domain from './modules/domain';
import * as company from './modules/company';
import * as scraper from './modules/scraper';
import * as interests from './modules/interests';
import * as website from './modules/website';
import * as analytics from './modules/analytics';
import * as algorithms from './modules/algorithms';
/* eslint-disable no-useless-catch */
export default {
  async upload(data = {}) {
    try {
      const response = await axios.post('/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  auth,
  algorithms,
  billing,
  user,
  domain,
  company,
  website,
  scraper,
  interests,
  analytics,
};
