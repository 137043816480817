import axios from '@services/api/axios';

export const fetch = async () => {
  const response = await axios.get('/company');

  return response.data;
};

export const update = async (payload) => {
  const response = await axios.put('/company', payload);

  return response.data;
};

export const create = async (payload) => {
  const response = await axios.post('/company/create', payload);

  return response.data;
};

export const activate = async (payload) => {
  const response = await axios.post('/company/switch', payload);

  return response.data;
};

export const remove = async () => {
  const response = await axios.delete('/company');

  return response.data;
};

export const fetchUserCompanies = async () => {
  const response = await axios.get('/company/user');

  return response.data;
};
