export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./page/dashboard-page.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
