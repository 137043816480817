import moment from 'moment';

const formatMessageData = (messages) => {
  if (!Array.isArray(messages)) return [];

  return messages.map((message) => ({
    ...message,
    isMessage: true,
    timeAgoFromNow: moment(message.createdAt).fromNow(),
    timeAgoFormatted: moment(message.createdAt).format('DD MMMM [at] HH:mm'),
    timestamp: message.createdAt,
  }));
};

const formatExternalData = (events) => {
  if (!Array.isArray(events)) return [];

  return events.map((event) => ({
    ...event,
    isExternal: true,
    timeAgoFromNow: moment(event.timestamp).fromNow(),
    timeAgoFormatted: moment(event.timestamp).format('DD MMMM [at] HH:mm'),
    timestamp: event.timestamp,
  }));
};

export default {
  formatMessageData,
  formatExternalData,
};
