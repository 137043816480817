const _state = {
  loading: false,
  collections: [],
  feeds: [],
  fetchedFeed: {},
  tags: [],
  pages: [],
  totalPages: 0,
  lastFetchedProduct: null,
};

const getters = {
  loading: (state) => state.loading,
  collections: (state) => state.collections,
  feeds: (state) => state.feeds,
  fetchedFeed: (state) => state.fetchedFeed,
  tags: (state) => state.tags,
  pages: (state) => state.pages,
  totalPages: (state) => state.totalPages,
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_COLLECTIONS: (state, collections) => {
    state.collections = collections;
  },
  SET_FEEDS: (state, feeds) => {
    state.feeds = feeds;
  },
  SET_FETCHED_FEED: (state, fetchedFeed) => {
    state.fetchedFeed = fetchedFeed;
  },
  SET_TAGS: (state, tags) => {
    state.tags = tags;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_PAGES: (state, pages) => {
    state.pages = pages;
  },
  SET_TOTAL_PAGES: (state, count) => {
    state.totalPages = count;
  },
  SET_PAGE_TAGS: (state, { id, tags }) => {
    state.pages = state.pages.map((page) => {
      if (page._id !== id) return page;

      return { ...page, tags };
    });
  },
  SET_LAST_FETCHED_PRODUCT: (state, fetchedProduct) => {
    state.lastFetchedProduct = fetchedProduct;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  async fetchCollections({ commit }) {
    commit('SET_LOADING', true);
    const collections = await this.$api.scraper.fetchCollections();
    commit('SET_COLLECTIONS', collections);
    commit('SET_LOADING', false);
    return collections;
  },
  async fetchCollectionsWithoutLoader({ commit }) {
    const collections = await this.$api.scraper.fetchCollections();
    commit('SET_COLLECTIONS', collections);
    return collections;
  },
  async createCollection({ dispatch }, payload) {
    const response = await this.$api.scraper.createCollection(payload);
    await dispatch('fetchCollectionsWithoutLoader');
    return response;
  },
  async getRecommendations({ state }, collectionId) {
    const coll = state.collections.find((entry) => entry.id === collectionId);
    return coll ? coll.items : [];
  },
  async fetchProduct({ state, commit }, payload) {
    if (payload.url === state.lastFetchedProduct?.full) return state.lastFetchedProduct;
    const fetchedProduct = await this.$api.scraper.fetchProduct(payload);
    commit('SET_LAST_FETCHED_PRODUCT', fetchedProduct);
    return fetchedProduct;
  },
  async fetchFeeds({ commit }) {
    commit('SET_LOADING', true);
    const feeds = await this.$api.scraper.fetchFeeds();
    commit('SET_FEEDS', feeds);
    commit('SET_LOADING', false);
    return feeds;
  },
  async fetchFeedsWithoutLoader({ commit }) {
    const feeds = await this.$api.scraper.fetchFeeds();
    commit('SET_FEEDS', feeds);
    return feeds;
  },
  async fetchFeedData({ commit }, payload) {
    const response = await this.$api.scraper.fetchFeedData(payload);

    commit('SET_FETCHED_FEED', response);

    return response;
  },
  async createFeed({ dispatch }, payload) {
    const response = await this.$api.scraper.createFeed(payload);

    await dispatch('fetchFeedsWithoutLoader');

    return response;
  },
  async removeFeed({ dispatch }, { id }) {
    const response = await this.$api.scraper.removeFeed(id);

    await dispatch('fetchFeedsWithoutLoader');

    return response;
  },
  async updateFeed({ dispatch }, payload) {
    const response = await this.$api.scraper.updateFeed(payload);

    await dispatch('fetchFeedsWithoutLoader');

    return response;
  },
  async fetchFeedFieldTypes() {
    return this.$api.scraper.fetchFeedFieldTypes();
  },
  async fetchScraperTemplateFields(_, id) {
    return this.$api.scraper.fetchScraperTemplateFields(id);
  },
  async createScraperTemplate(_, scraperTemplate) {
    return this.$api.scraper.createScraperTemplate(scraperTemplate);
  },
  async updateScraperTemplate(_, scraperTemplate) {
    return this.$api.scraper.updateScraperTemplate(scraperTemplate);
  },
  async fetchTags({ commit, dispatch }) {
    const dynamicInterests = await dispatch('interests/fetch', null, { root: true });

    const dynamicTags = dynamicInterests.map((interest) => interest.tags).flat();
    const scraperTags = await this.$api.scraper.fetchTags();

    const tags = [...new Set([...dynamicTags, ...scraperTags.map((item) => item._id)])].map((tag) => ({ _id: tag }));
    commit('SET_TAGS', tags);
    return tags;
  },
  async searchTags({ commit, rootGetters }, query) {
    const scraperTags = await this.$api.scraper.fetchTags(query);

    const filtered = rootGetters['interests/tags'].filter((tagItem) => new RegExp(query, 'i').test(tagItem));

    const tags = [...new Set([...filtered, ...scraperTags.map((item) => item._id)])].map((tag) => ({ _id: tag }));

    commit('SET_TAGS', tags);

    return tags;
  },
  async fetchPages({ commit }, { collection, page, active }) {
    commit('SET_LOADING', true);
    const { pages, count } = await this.$api.scraper.fetchPages({ collection, page, active });

    commit('SET_PAGES', pages);
    commit('SET_LOADING', false);
    commit('SET_TOTAL_PAGES', count);
    return pages;
  },
  async searchPages({ commit }, { query, page }) {
    const { pages } = await this.$api.scraper.searchPages({ query, page });

    commit('SET_PAGES', pages);
    return pages;
  },
  async createPage(_, { body }) {
    const resp = await this.$api.scraper.createPage(body);
    return resp;
  },
  async updatePage({ commit }, { id, body }) {
    const resp = await this.$api.scraper.updatePage(id, body);

    commit('SET_PAGE_TAGS', { id, tags: resp.tags });
    return resp;
  },
  async removePage(_, { id }) {
    const resp = await this.$api.scraper.removePage(id);
    return resp;
  },
  async triggerFeeds() {
    const response = await this.$api.scraper.triggerFeeds();
    return response;
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
