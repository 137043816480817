import accountRoutes from './account/account-routes';
import domainRoutes from './domain/domain-routes';
import companyRoutes from './company/company-routes';

export default [
  {
    path: '/settings',
    children: [
      {
        path: '',
        redirect: '/settings/account/profile',
      },
      ...accountRoutes,
      ...domainRoutes,
      ...companyRoutes,
    ],
  },
];
