import axios from 'axios';
import * as Sentry from '@sentry/vue';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const paywallStatus = 402;
    const unAuthorizedStatus = 401;

    Sentry.withScope((scope) => {
      if (error.status === paywallStatus || error.status === unAuthorizedStatus) scope.setFingerprint([error.status, error.response.data.message]);
      else scope.setFingerprint([error.config.method, error.config.url, error.status]);
    });

    Sentry.setTag('call-path', error.config.url);
    Sentry.setTag('call-method', error.config.method);

    if (error.response?.data?.status === paywallStatus) {
      Sentry.withScope((scope) => {
        scope.setTag('error_type', 'paywall');
        scope.setContext('response', {
          url: error.config.url,
          status: error.response.status,
          message: error.response.data.message,
        });
        Sentry.captureMessage('Paywal encountered', 'info');
      });
      return Promise.reject(error.response.data);
    }
    if (!error.response || !error.response.data) return Promise.reject(new Error(error.message));

    return Promise.reject(new Error(error.response.data.message));
  },
);

export default instance;
