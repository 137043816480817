<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script setup>
import { inject, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '../layouts/main-app-layout.vue';

const route = useRoute();

const $notify = inject('$notify');
const $socket = inject('$socket');

onMounted(() => {
  if (route.params?.updated_app) {
    $notify({
      title: 'Update!',
      text: 'We\'ve updated the Taglayer app for you. Enjoy the new features',
      type: 'info',
    });
  }

  $socket.on('snippet-complete', (payload) => {
    $notify({
      title: payload.title,
      text: payload.message,
      type: 'success',
    });
  });

  $socket.on('snippet-fail', (payload) => {
    $notify({
      title: payload.title,
      text: payload.message,
      type: 'danger',
    });
  });
});

onUnmounted(() => {
  $socket.off('snippet-complete');
  $socket.off('snippet-fail');
});
</script>

<style lang="postcss">
html {
  @apply overflow-hidden;
}

</style>
