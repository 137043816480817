import axios from '@services/api/axios';

export const fetchRfms = async () => {
  const response = await axios.get('/website/rfm');
  return response.data;
};

export const createRfm = async (payload) => {
  const response = await axios.post('/website/rfm/create', payload);
  return response.data;
};

export const updateRfm = async (id, { name, config, active }) => {
  const response = await axios.put(`/website/rfm/update/${id}`, { name, config, active });
  return response.data;
};

export const deleteRfm = async (id) => {
  const response = await axios.delete(`/website/rfm/delete/${id}`);
  return response.data;
};

export const generateRfm = async (conversions) => {
  const response = await axios.post('/website/rfm/generate', conversions);
  return response.data;
};
