import axios from '@services/api/axios';

export const fetchCases = async () => {
  const response = await axios.get('/website/case?deleted=true');

  return response.data;
};

export const createCase = async (payload) => {
  const response = await axios.post('/website/case', payload);

  return response.data;
};

export const getCaseByID = async (caseID) => {
  const response = await axios.get(`/website/case/${caseID}`);

  return response.data;
};

export const updateCase = async ({ id, data }) => {
  const response = await axios.put(`/website/case/${id}`, data);

  return response.data;
};

export const publishCase = async (payload) => {
  const response = await axios.post('/website/snippet/publish/case', payload);

  return response;
};

export const duplicateCase = async (caseId) => {
  const response = await axios.post(`/website/case/${caseId}/duplicate`, caseId);

  return response.data;
};

export const removeCase = async (id) => {
  const response = await axios.delete(`/website/case/${id}`);

  return response.data;
};

export const fetchActiveCases = async () => {
  const response = await axios.get('/website/case/active');

  return response.data;
};
