import { createRouter, createWebHistory } from 'vue-router';

import store from '@store';

import routes from '@/app/app-routes';
import NotFound from '@/app/error/not-found.vue';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({ speed: 450, showSpinner: true, easing: 'linear', trickleSpeed: 100 });

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to) => {
  NProgress.start();
  if (to.meta.requiresAuth) {
    if (!store.getters['auth/user']) {
      try {
        await store.dispatch('auth/fetch');
      } catch (error) {
        NProgress.done();

        return '/auth/login';
      }
      NProgress.done();
      return !!store.getters['auth/user'] || '/auth/login';
    }
  }

  return true;
});

router.onError(() => {
  NProgress.done();
});

router.afterEach(() => {
  NProgress.done();
});

/* router.onError(() => {
  // Add check for /assests/ later and only then do the refresh with notify
  // TypeError: Failed to fetch dynamically imported module: https://dev.taglayer.com/assets/journeys.a8e4f72c.js
  // const currentUrl = window.location.origin + window.location.pathname;
  // const updatedUrl = `${currentUrl}?updated_app=true`;

  // window.location.href = updatedUrl;
}); */

export default router;
