const _state = {
  template: {},
  selectedId: '',
  sidebar: 'plugins',
};

const getters = {
  selectedElement: (state) => state.template?.elements.find((el) => el.identifier === state.selectedId),
};

/* eslint-disable no-param-reassign */
const mutations = {
  SET_SELECTED_ID(state, selectedId) {
    state.selectedId = selectedId;
  },
  SET_SIDEBAR(state, sidebar) {
    state.sidebar = sidebar;
  },
  SET_TEMPLATE(state, template) {
    state.template = template;
  },
};
/* eslint-enable no-param-reassign */

const actions = {
  updateSelectedConfig({ commit, state }, config) {
    const elements = state.template.elements.map((element) => {
      if (element.identifier === state.selectedId) {
        return {
          ...element,
          config: JSON.parse(JSON.stringify(config)),
        };
      }
      return element;
    });
    commit('SET_TEMPLATE', {
      ...state.template,
      elements,
    });
  },
  updateSelected({ commit, state }, selected) {
    const elements = state.template.elements.map((element) => {
      if (element.identifier === state.selectedId) {
        return {
          ...selected,
        };
      }
      return element;
    });
    commit('SET_TEMPLATE', {
      ...state.template,
      elements,
    });
  },
  async saveCurrentTemplate({ dispatch, state }) {
    if (!state.template) return;
    await dispatch('email/templates/update', state.template, { root: true });
  },
};

export default {
  namespaced: true,
  state: _state,
  getters,
  actions,
  mutations,
};
